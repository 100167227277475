import { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { connect } from "react-redux";
import CustomGrid from "../../../shared/CustomGrid";
import dataSource from "../../../../dataSource/dataSource";

const EMPTY_ELEVAGE = {
  codeElevage: null,
  domaine: "INZO",
  domaineId: null,
  groupeId: null,
  groupeParDefaut: 1,
  groupeSteAgricoleId: 0,
  nom: null,
  nomElevage: null,
  ordreAnnuel: 1,
  ordreMensuel: 1,
  raisonSociale: null,
  societeAgricoleId: null,
  typeElevage: null,
};

const mapStateToProps = (state) => ({
  domaineId: state.auth.domaineId,
});

class Elevages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      elevages: [],
      typesElevage: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const elevages = await dataSource.loadAllElevages();
    elevages.sort((a, b) => {
      const nomElevageA = (a.nomElevage || "").toLowerCase();
      const nomElevageB = (b.nomElevage || "").toLowerCase();
      if (nomElevageA < nomElevageB) return -1;
      if (nomElevageA > nomElevageB) return 1;
      return 0;
    });

    const typesElevage = await dataSource.loadModaliste("TYEL");
    this.setState({ loading: false, elevages, typesElevage });
  }

  updateSocietesAgricoles = (societesAgricoles) => {
    const { typesElevage } = this.state;

    return societesAgricoles.map((e) => {
      const te = typesElevage.find((t) => t.rang_modaliste === e.typeElevage);

      return {
        ...e,
        nomElevageCode: `${e.nomElevage || ""} - ${e.codeElevage || ""}`,
        typeElevageLib: e.typeElevageLib || te?.libelle || "",
      };
    });
  };

  columns = () => {
    const { elevages } = this.state;
    const { onChange, valueGetter } = this.props;

    const societesAgricoles = this.updateSocietesAgricoles(
      valueGetter("societesAgricoles") || []
    );
    return [
      {
        title: "Ordonner",
        type: "sorting",
        width: 60,
        sorting: {
          isUpShown: ({ gridIndex }) => gridIndex > 0,
          isDownShown: ({ gridIndex }) =>
            gridIndex < societesAgricoles.length - 1,
          onMoveClick: (idx, dir) => {
            const el = societesAgricoles.splice(idx, 1)[0];
            societesAgricoles.splice(idx + dir, 0, el);
            const newSocietesAgricoles = societesAgricoles.map(
              (g, gridIndex) => ({
                ...g,
                gridIndex,
                ordreMensuel: gridIndex + 1,
                ordreAnnuel: gridIndex + 1,
              })
            );
            onChange("societesAgricoles", { value: newSocietesAgricoles });
          },
        },
      },
      {
        title: "Ordre",
        type: "number",
        field: "ordreMensuel",
        editable: () => false,
        width: 20,
      },
      {
        title: "Nom élevage - code",
        type: "combobox",
        field: "nomElevageCode",
        combobox: {
          list: elevages,
          textField: "nomElevage",
          dataItemKey: "societeAgricoleId",
          value: (dataItem) => {
            const e = elevages.find(
              (el) => el.societeAgricoleId === dataItem.societeAgricoleId
            );
            return { nomElevage: e?.nomElevage || "" };
          },
          updateItem: (dataItem, v) => {
            const { idGroupe, domaineId } = this.props;

            return {
              ...dataItem,
              codeElevage: v.codeElevage,
              nomElevage: v.nomElevage,
              societeAgricoleId: v.societeAgricoleId,
              groupeId: idGroupe,
              domaineId,
              typeElevageLib: v.typeElevageLibelle,
            };
          },
        },
        width: 150,
      },
      {
        title: "Type élevage",
        type: "text",
        field: "typeElevageLib",
        editable: () => false,
        width: 100,
      },
      {
        title: "Groupe par défaut",
        type: "checkbox",
        field: "groupeParDefaut",
        width: 100,
      },
      {
        type: "delete",
        width: 60,
        headerClassName: "delete",
      },
    ];
  };

  render() {
    const { edit, onChange, onAddClick, valueGetter } = this.props;
    const { loading } = this.state;

    const elevages = this.updateSocietesAgricoles(
      valueGetter("societesAgricoles") || []
    );

    return loading === true ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <div>
        <Button
          className="k-mb-4 k-mt-2"
          icon="add"
          type="button"
          onClick={() => {
            onAddClick();
            const newElevages = [...elevages, EMPTY_ELEVAGE];
            onChange("societesAgricoles", { value: newElevages });
          }}
        >
          Ajouter un élevage
        </Button>
        <CustomGrid
          className="grid-admin"
          style={{ maxHeight: "60vh" }}
          columns={this.columns()}
          dataGrid={elevages}
          canEdit={edit}
          onChange={(newData) => {
            onChange("societesAgricoles", {
              value: newData.map((g, gridIndex) => ({
                ...g,
                gridIndex,
                ordreMensuel: gridIndex + 1,
                ordreAnnuel: gridIndex + 1,
              })),
            });
          }}
        />
      </div>
    );
  }
}

Elevages.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  idGroupe: PropTypes.string,
  domaineId: PropTypes.number.isRequired,
};

Elevages.defaultProps = {
  idGroupe: -1,
};

export default connect(mapStateToProps)(Elevages);
