import dayjs from "dayjs";
import { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import Elevages from "./components/administration/elevages";
import Groupes from "./components/administration/groupes";
import Cooperatives from "./components/administration/cooperatives";
import SaisieCooperative from "./components/administration/saisieCooperative";
import Techniciens from "./components/administration/techniciens";
import SaisieElevage from "./components/administration/saisieElevage";
import SaisieTechnicien from "./components/administration/saisieTechnicien";
import SaisieGroupe from "./components/administration/saisieGroupe";
import DonneesAnnuelles from "./components/DonneesAnnuelles/DonneesAnnuelles";
import SaisieDonneesAnnuelles from "./components/DonneesAnnuelles/SaisieDonneesAnnuelles";
import SaisieDonneeMensuelles from "./components/donneesMensuelles/catalogue/SaisieDonneesMensuelles";
import FicheDonneeMensuelle from "./components/donneesMensuelles/fiche/FicheDonneeMensuelle";
import GenerationDonneesMensuelles from "./components/donneesMensuelles/generation/GenerationDonneesMensuelles";
import Home from "./components/home";
import Component404 from "./components/shared/404";
import Assistance from "./components/user/Assistance";
import InfosPersonnelles from "./components/user/InfosPersonnelles";
import { attemptLogout, attemptRefreshToken } from "./redux/actions/auth";
import PaieDeLait from "./components/donneesMensuelles/catalogue/PaieDeLait";
import { loadAadConfig } from "./Azure.js";

const clientInactivityLimit =
  Number(process.env.REACT_APP_CLIENT_INACTIVITY_LIMIT) || 1800;

const mapStateToProps = (state) => ({
  sessionExpirationTime: state.auth.sessionExpirationTime,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  handleLogout: async (causedByInactivity) => {
    try {
      await dispatch(attemptLogout());
    } catch (error) {
      if (error.status !== 401) {
        throw error;
      }
    } finally {
      ownProps.history.replace(
        `/login?disconnected=${
          !causedByInactivity ? "session-expired" : "inactivity"
        }`
      );
    }
  },
  handleRefreshToken: () => dispatch(attemptRefreshToken()),
});
class AppRouter extends Component {
  interval;

  secondsSinceLastActivity = 0;

  async componentDidMount() {
    const { history } = this.props;
    await loadAadConfig(history);
    this.checkTokenExpiration();
    [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ].forEach((event) => document.addEventListener(event, this.setActivity));

    // TODO: A voir avec le client
    // this.interval = setInterval(() => {
    //   this.secondsSinceLastActivity += 1;
    //   this.checkTokenExpiration();
    // }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkTokenExpiration = () => {
    const {
      handleLogout,
      sessionExpirationTime,
      handleRefreshToken,
    } = this.props;
    const now = dayjs();
    const secondsLeft = now.diff(sessionExpirationTime, "seconds") * -1;
    if (
      this.secondsSinceLastActivity >= clientInactivityLimit ||
      secondsLeft < 0
    ) {
      handleLogout(secondsLeft >= 0);
    }
    // check JWT session expiration time
    else if (secondsLeft < 40) {
      // refresh token call if token has less than 40 seconds left (chosen arbitrarily)
      handleRefreshToken();
    }
  };

  setActivity = () => {
    this.secondsSinceLastActivity = 0;
  };

  render() {
    return (
      <Switch>
        <Route path="/app/administration/elevages/nouveau">
          <SaisieElevage createNew />
        </Route>
        <Route path="/app/administration/elevages/:idElevage">
          {({
            match: {
              params: { idElevage },
            },
          }) => <SaisieElevage idElevage={idElevage} />}
        </Route>
        <Route path="/app/administration/elevages">
          <Elevages />
        </Route>
        <Route path="/app/administration/techniciens/nouveau">
          <SaisieTechnicien createNew />
        </Route>
        <Route path="/app/administration/techniciens/:idTechnicien">
          {({
            match: {
              params: { idTechnicien },
            },
          }) => <SaisieTechnicien idTechnicien={idTechnicien} />}
        </Route>
        <Route path="/app/administration/techniciens">
          <Techniciens />
        </Route>
        <Route path="/app/administration/cooperatives/nouveau">
          <SaisieCooperative createNew />
        </Route>
        <Route path="/app/administration/cooperatives/:idCooperative">
          {({
            match: {
              params: { idCooperative },
            },
          }) => <SaisieCooperative idCooperative={idCooperative} />}
        </Route>
        <Route path="/app/administration/cooperatives">
          <Cooperatives />
        </Route>
        <Route path="/app/administration/groupes/nouveau">
          <SaisieGroupe createNew />
        </Route>
        <Route path="/app/administration/groupes/duplicate">
          {() => <SaisieGroupe mode={"duplicate"} edit />}
        </Route>
        <Route path="/app/administration/groupes/:idGroupe/edit">
          {({
            match: {
              params: { idGroupe },
            },
          }) => <SaisieGroupe idGroupe={idGroupe} edit />}
        </Route>
        <Route path="/app/administration/groupes/:idGroupe">
          {({
            match: {
              params: { idGroupe },
            },
          }) => <SaisieGroupe idGroupe={idGroupe} />}
        </Route>
        <Route path="/app/administration/groupes">
          <Groupes />
        </Route>
        <Route path="/app/donnees-mensuelles/fiche/:nomElevage/:idFiche">
          {({ match }) => (
            <FicheDonneeMensuelle idFiche={match.params.idFiche} />
          )}
        </Route>
        <Route path="/app/donnees-mensuelles/generation">
          <GenerationDonneesMensuelles />
        </Route>
        <Route path="/app/infos-personnelles">
          <InfosPersonnelles />
        </Route>
        <Route path="/app/donnees-mensuelles/saisie">
          <SaisieDonneeMensuelles />
        </Route>
        <Route path="/app/donnees-mensuelles/paie-de-lait">
          <PaieDeLait />
        </Route>
        <Route path="/app/assistance">
          <Assistance />
        </Route>
        <Route path="/app/donnees-annuelles/:nomElevage/:groupeId/:idFiche">
          {({
            match: {
              params: { idFiche, groupeId },
            },
          }) => (
            <SaisieDonneesAnnuelles
              groupeId={Number(groupeId)}
              idFiche={idFiche}
            />
          )}
        </Route>
        <Route path="/app/donnees-annuelles">
          <DonneesAnnuelles />
        </Route>

        <Route path="/app" exact>
          <Home />
        </Route>
        <Route>
          <Component404 />
        </Route>
      </Switch>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppRouter);
