import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Form, FormElement } from "@progress/kendo-react-form";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import dayjs from "dayjs";
import Description from "./fiche/elevage/Description";
import Contact from "./fiche/elevage/Contact";
import Structures from "./fiche/elevage/Structures";
import Techniciens from "./fiche/elevage/Techniciens";
import Groupes from "./fiche/elevage/Groupes";
import dataSource from "../../dataSource/dataSource";
import toastService from "../../utils/toastService";
import truncTimeZone from "../../utils/dateTools";
import { RULE, LIMIT } from "../shared/constants";

const mapStateToProps = (state) => ({
  utilisateurId: state.auth.utilisateurId,
  domaineId: state.auth.domaineId,
});

class SaisieElevage extends Component {
  constructor(props) {
    super(props);
    this.formRef = createRef();
    this.state = {
      selectedTab: 0,
      edit: false,
      loading: false,
      structure: undefined,
      structures: [],
      newElevage: undefined,
    };
  }

  async componentDidMount() {
    const { idElevage, createNew, domaineId } = this.props;
    this.setState({ loading: true });
    const structures = await dataSource.loadStructures();
    if (createNew) {
      const defaultStructure = structures.find((s) => s.coopId === domaineId);
      const today = dayjs().format("YYYY-MM-DD");
      this.setState({
        edit: true,
        structures,
        newElevage: {
          societeAgricoleId: 0,
          dateDebut: today,
          contact: {
            dateDebut: today,
            typeUser: RULE.CONTACT_ELEVEUR,
          },
          // Par défaut, il y a au moins une structure correspondant au domaine
          // de l'utilisateur connecté
          structures: [
            {
              ...defaultStructure,
              codeCoop: defaultStructure.code,
              typeCoop: defaultStructure.typeCoopLibelle,
              // TODO: Vérifier si c'est le même droit que RULE.SUPERVISEUR_DOMAINE auquel cas
              // il faudra remplacer cette valeur numérique par la constante
              droit: 1, // 1 === admin
              dateDebut: today,
            },
          ],
        },
        loading: false,
      });
      return;
    }
    const structure = await dataSource.loadSocietesAgricolesById(idElevage);
    this.setState({
      structure,
      structures,
      loading: false,
    });
  }

  handleOnClickEdit = () => this.setState((s) => ({ edit: !s.edit }));

  validator = (values, valueGetter) => {
    const { createNew } = this.props;
    const errors = {};
    errors.description = {};
    errors.contact = {};
    if (!valueGetter("code")) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        code: {
          level: "error",
          message: "Saisie obligatoire du code",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("nom")) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        nom: {
          level: "error",
          message: "Saisie obligatoire du nom",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("typeElevageChevre")) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        typeElevageChevre: {
          level: "error",
          message: "Saisie obligatoire du type d'élevage",
          tabIndex: 0,
        },
      };
    }
    if (
      !valueGetter("dateDebut") ||
      valueGetter("dateDebut") === "Invalid Date" ||
      (valueGetter("dateDebut") instanceof Date &&
        valueGetter("dateDebut").getFullYear() <
          LIMIT.MIN_KENDO_DATEPICKER_YEAR)
    ) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        dateDebut: {
          level: "error",
          message: "Saisie obligatoire de la date de début",
          tabIndex: 0,
        },
      };
    }
    if (
      valueGetter("dateFin") instanceof Date &&
      valueGetter("dateFin").getFullYear() < LIMIT.MIN_KENDO_DATEPICKER_YEAR
    ) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        dateFin: {
          level: "error",
          message: "La date de fin est erronée",
          tabIndex: 0,
        },
      };
    }
    if (createNew && !valueGetter("contact.motDePasse")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        motDePasse: {
          level: "error",
          message: "Saisie obligatoire du mot de passe du contact",
          tabIndex: 1,
        },
      };
    }
    if (!valueGetter("contact.nom")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        nom: {
          level: "error",
          message: "Saisie obligatoire du nom du contact",
          tabIndex: 1,
        },
      };
    }
    if (!valueGetter("contact.identifiant")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        identifiant: {
          level: "error",
          message: "Saisie obligatoire de l'identifiant du contact",
          tabIndex: 1,
        },
      };
    }
    if (
      !valueGetter("contact.dateDebut") ||
      valueGetter("contact.dateDebut") === "Invalid Date" ||
      (valueGetter("contact.dateDebut") instanceof Date &&
        valueGetter("contact.dateDebut").getFullYear() <
          LIMIT.MIN_KENDO_DATEPICKER_YEAR)
    ) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        dateDebut: {
          level: "error",
          message: "Saisie obligatoire de la date de début du contact",
          tabIndex: 1,
        },
      };
    }
    if (
      valueGetter("contact.dateFin") instanceof Date &&
      valueGetter("contact.dateFin").getFullYear() <
        LIMIT.MIN_KENDO_DATEPICKER_YEAR
    ) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        dateFin: {
          level: "error",
          message: "La date de fin est erronée",
          tabIndex: 1,
        },
      };
    }

    const isFormValid = !Object.values(errors)
      .map((item) => Object.values(item).length)
      .some((item) => item > 0);
    return isFormValid ? {} : errors;
  };

  submitValues = async ({ values, isValid }) => {
    const { createNew } = this.props;
    if (!isValid) return;
    if (createNew) {
      try {
        const newSocieteAgricole = await dataSource.postSocieteAgricole({
          ...values,
          codeSiret: values.codeSiret || "",
          raisonSocialeId: values.raisonSocialeId || 1,
          dateDebut: truncTimeZone(values.dateDebut),
          dateFin: truncTimeZone(values.dateFin),
          contact: {
            ...values.contact,
            utilisateurId: 0,
            dateDebut: truncTimeZone(values.contact.dateDebut),
            dateFin: truncTimeZone(values.contact.dateFin),
          },
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été enregistrées",
        });
        window.location = `${process.env.PUBLIC_URL}/app/administration/elevages/${newSocieteAgricole.societeAgricoleId}`;
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    } else {
      try {
        await dataSource.putSocieteAgricole(values.societeAgricoleId, {
          ...values,
          codeSiret: values.codeSiret || "",
          raisonSocialeId: values.raisonSocialeId || 1,
          dateDebut: truncTimeZone(values.dateDebut),
          dateFin: truncTimeZone(values.dateFin),
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été mises à jour",
        });
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    }
  };

  render() {
    const { createNew, history } = this.props;
    const {
      selectedTab,
      edit,
      loading,
      structure,
      structures,
      newElevage,
    } = this.state;
    // TODO à supprimer
    const typeUtilisateurId = 1;
    return loading ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <Form
        ref={this.formRef}
        initialValues={createNew ? newElevage : structure}
        onSubmitClick={this.submitValues}
        validator={this.validator}
        render={(formProps) => {
          return (
            <div className="k-d-flex k-flex-column k-m-4">
              <FormElement>
                <div className="k-display-flex k-justify-content-between k-align-items-center k-mb-4">
                  <div className="k-d-inline-flex k-align-items-baseline">
                    <h3 className="k-my-0">
                      {createNew ? "Ajout" : "Édition"} d&#39;un élevage
                    </h3>
                  </div>
                </div>
                {Object.keys(formProps.errors).filter(
                  (k) => typeof formProps.errors[k] === "object"
                ).length > 0 && (
                  <div className="row">
                    <div className="panel-error k-my-4">
                      <PanelBar>
                        <PanelBarItem
                          title="Erreurs dans le formulaire"
                          className="k-state-expanded"
                          style={{ width: "100%" }}
                        >
                          {Object.keys(formProps.errors)
                            .filter(
                              (k) => typeof formProps.errors[k] === "object"
                            )
                            .map((k) => formProps.errors[k])
                            .map(({ tabTitle, ...errors }) => {
                              return !!tabTitle &&
                                Object.values(errors).filter(
                                  (error) => !!error.message
                                ).length > 0 ? (
                                <div key={tabTitle}>
                                  <h3 style={{ marginBottom: "0.5em" }}>
                                    {tabTitle}
                                  </h3>
                                  {Object.entries(errors).map(
                                    ([key, { message, lines, tabIndex }]) => (
                                      <div key={key}>
                                        <Button
                                          type="button"
                                          key={key}
                                          className="k-mb-2 button-error"
                                          onClick={() =>
                                            this.setState({
                                              selectedTab:
                                                tabIndex != null ? tabIndex : 0,
                                            })
                                          }
                                        >
                                          {message}{" "}
                                          {lines && lines.length > 0 && (
                                            <span>
                                              (Ligne
                                              {lines.length > 1 && "s"}{" "}
                                              {lines
                                                .map((l) => l + 1)
                                                .join(",")}
                                              )
                                            </span>
                                          )}
                                        </Button>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null;
                            })}
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                )}
                <TabStrip
                  selected={selectedTab}
                  onSelect={({ selected }) =>
                    this.setState({
                      selectedTab: selected,
                    })
                  }
                  className="tab-strip-responsive"
                >
                  <TabStripTab title="Description">
                    <Description
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      errors={formProps.errors}
                    />
                  </TabStripTab>
                  <TabStripTab title="Contact">
                    <Contact
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      createNew={createNew}
                      errors={formProps.errors}
                    />
                  </TabStripTab>
                  <TabStripTab title="Structures">
                    <Structures
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      onAddClick={() => this.setState({ edit: true })}
                      structures={structures}
                    />
                  </TabStripTab>
                  <TabStripTab title="Techniciens">
                    <Techniciens
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      onAddClick={() => this.setState({ edit: true })}
                      societeAgricoleId={
                        createNew
                          ? newElevage?.societeAgricoleId || 0
                          : structure?.societeAgricoleId || 0
                      }
                    />
                  </TabStripTab>
                  <TabStripTab title="Groupes">
                    <Groupes valueGetter={formProps.valueGetter} />
                  </TabStripTab>
                </TabStrip>
              </FormElement>
              <div className="k-display-flex k-justify-content-center sticky-footer">
                {
                  <Button
                    className="k-mr-4"
                    icon="close"
                    title="Fermer"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Fermer
                  </Button>
                }
                {/* eslint-disable-next-line eqeqeq */}
                {(typeUtilisateurId === RULE.SUPERVISEUR_DOMAINE ||
                  typeUtilisateurId === RULE.TECHNICIEN_COOP) &&
                  !edit && (
                    <Button
                      className="k-mr-4"
                      icon={edit ? "undo" : "pencil"}
                      title={edit ? "Visualiser" : "Editer"}
                      onClick={() => {
                        this.handleOnClickEdit();
                      }}
                    >
                      Editer
                    </Button>
                  )}
                {edit && (
                  <>
                    <Button
                      data-cy="submit_form"
                      icon="save"
                      onClick={() => {
                        formProps.onSubmit();
                      }}
                    >
                      Enregistrer les données
                    </Button>
                    <Button
                      className="k-ml-4"
                      icon="reload"
                      onClick={() => window.location.reload()}
                    >
                      Annuler la saisie
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

SaisieElevage.propTypes = {
  idElevage: PropTypes.string,
  createNew: PropTypes.bool,
  history: PropTypes.object.isRequired,
  domaineId: PropTypes.number.isRequired,
};

SaisieElevage.defaultProps = {
  idElevage: "",
  createNew: false,
};
export default compose(connect(mapStateToProps), withRouter)(SaisieElevage);
