import dayjs from "dayjs";
import { Component } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";

import { Label } from "@progress/kendo-react-labels";
import CustomGrid from "../shared/CustomGrid";
import dataSource from "../../dataSource/dataSource";

const FIELDS_CONFIGURATION = {
  code: { type: "number" },
  structure: { type: "string" },
  raisonSociale: { type: "string" },
  codePostal: { type: "string" },
  commune: { type: "string" },
  codeSiret: { type: "string" },
  dateDebut: { type: "date", format: "DD/MM/YYYY" },
  dateFin: { type: "date", format: "DD/MM/YYYY" },
};

class Elevages extends Component {
  columns = [
    {
      title: "Actions",
      type: "actions",
      actions: [
        {
          title: "Modifier",
          icon: "pencil",
          action: (dataItem) =>
            this.goToAdminElevage(dataItem.societeAgricoleId),
        },
      ],
      editable: false,
      width: 95,
    },
    {
      title: "Structure",
      type: "text",
      field: "structure",
      width: 110,
    },
    {
      title: "Code",
      type: "text",
      field: "code",
      width: 90,
    },
    {
      title: "Raison sociale",
      type: "text",
      field: "raisonSociale",
      width: 180,
    },
    {
      title: "CP",
      type: "text",
      field: "codePostal",
      width: 90,
    },
    {
      title: "Ville",
      type: "text",
      field: "commune",
      width: 130,
    },
    {
      title: "SIRET",
      type: "text",
      field: "codeSiret",
      width: 120,
    },
    {
      title: "Date début",
      type: "text",
      field: "dateDebut",
      width: 130,
    },
    {
      title: "Date fin",
      type: "text",
      field: "dateFin",
      width: 130,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      panes: [
        {
          keepMounted: true,
          size: "310px",
        },
        {},
      ],
      loading: false,
      structures: undefined,
      filters: {
        code: "",
        raisonSociale: "",
        cP: "",
        ville: "",
        codeSiret: "",
        dateDebut: { start: "", end: "" },
        dateFin: { start: "", end: "" },
        active: true,
      },
      sort: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    const structures = await dataSource.loadSocietesAgricoles();
    this.setState({ structures, loading: false });
  }

  handleOnFilterChange = ({ value, target: { name } }) => {
    this.setState((s) => ({
      filters: {
        ...s.filters,
        [name]: value,
      },
    }));
  };

  handleOnDateFilterChange = (key) => ({ value: { start, end } }) => {
    this.setState((s) => ({
      filters: {
        ...s.filters,
        [key]: {
          start: dayjs(start).isValid()
            ? dayjs(start).format("YYYY-MM-DD")
            : "",
          end: dayjs(end).isValid() ? dayjs(end).format("YYYY-MM-DD") : "",
        },
      },
    }));
  };

  handleOnFilterClick = async () => {
    const { filters } = this.state;
    this.setState({ loading: true });
    const structures = await dataSource.loadSocietesAgricoles({
      ...filters,
      active: Number(filters.active),
    });
    this.setState({ structures, loading: false });
  };

  handleOnKeyDown = ({ key }) => {
    if (key === "Enter") {
      this.handleOnFilterClick();
    }
  };

  goToAdminElevage = (id) => {
    const { history } = this.props;
    history.push(`/app/administration/elevages/${id}`);
  };

  handleOnAddClick = () => {
    const { history } = this.props;
    history.push(`/app/administration/elevages/nouveau`);
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "300px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  handleOnSortChange = ({ sort: s }) => {
    if (s.length > 0)
      this.setState({
        sort: [{ ...s[0], ...FIELDS_CONFIGURATION[s[0].field] }],
      });
    else {
      this.setState({ sort: s });
    }
  };

  render() {
    const {
      panes,
      structures,
      loading,
      filters: { code, raisonSociale, cP, ville, codeSiret, active },
      sort,
    } = this.state;

    return (
      <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-4">
        <Splitter
          panes={panes}
          style={{ height: "100%" }}
          onChange={({ newState }) => this.setState({ panes: newState })}
        >
          <>
            <h2 className="k-ml-4 k-my-2 k-pt-0">Élevages</h2>
            <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-3 admin-filter-elevage">
              <div className="k-d-flex k-align-items-baseline k-mb-3">
                <Checkbox
                  label="Actif"
                  value={active}
                  name="active"
                  onChange={({ value }) =>
                    this.handleOnFilterChange({
                      value,
                      target: { name: "active" },
                    })
                  }
                />
              </div>
              <p className="k-mb-0 k-mt-0">Filtrer les structures par :</p>
              <div className="k-d-flex k-align-items-baseline k-mb-1">
                <Input
                  onKeyDown={this.handleOnKeyDown}
                  value={code}
                  name="code"
                  onChange={this.handleOnFilterChange}
                  label="Code"
                />
              </div>
              <div className="k-d-flex k-align-items-baseline k-mb-1">
                <Input
                  onKeyDown={this.handleOnKeyDown}
                  value={raisonSociale}
                  name="raisonSociale"
                  onChange={this.handleOnFilterChange}
                  label="Raison sociale"
                />
              </div>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 100 }}>
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={cP}
                    name="cP"
                    onChange={this.handleOnFilterChange}
                    label="Code postal"
                  />
                </div>
                <div className="k-mb-1">
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={ville}
                    name="ville"
                    onChange={this.handleOnFilterChange}
                    label="Ville"
                  />
                </div>
              </div>
              <div className="k-d-flex k-align-items-baseline k-mb-3">
                <Input
                  onKeyDown={this.handleOnKeyDown}
                  value={codeSiret}
                  name="codeSiret"
                  onChange={this.handleOnFilterChange}
                  label="SIRET"
                />
              </div>
              <div className="k-d-flex k-flex-col k-align-items-baseline k-my-4">
                <Label className="k-font-weight-bold">Date de début</Label>
                <DateRangePicker
                  style={{ maxWidth: "100%" }}
                  startDateInputSettings={{
                    label: "du",
                  }}
                  endDateInputSettings={{
                    label: "au",
                  }}
                  onChange={this.handleOnDateFilterChange("dateDebut")}
                />
              </div>
              <div className="k-d-flex k-flex-col k-align-items-baseline k-mb-8">
                <Label className="k-font-weight-bold">Date de fin</Label>
                <DateRangePicker
                  style={{ maxWidth: "100%" }}
                  startDateInputSettings={{
                    label: "du",
                  }}
                  endDateInputSettings={{
                    label: "au",
                  }}
                  onChange={this.handleOnDateFilterChange("dateFin")}
                />
              </div>

              <Button type="button" themeColor="primary" onClick={this.handleOnFilterClick}>
                Filtrer
              </Button>
            </div>
          </>
          <div className="k-m-4">
            {loading === true && (
              <div className="flex-fill k-text-center k-mt-18">
                <Loader themeColor="primary" />
              </div>
            )}
            {loading === false && structures !== undefined && (
              <>
                <Button
                  title="Filtres"
                  icon="filter"
                  themeColor="primary"
                  onClick={this.toggleFilters}
                  style={{
                    top: 0,
                    position: "sticky",
                    zIndex: 1,
                  }}
                  className="k-mr-4"
                />
                <Button
                  title="Ajouter un élevage"
                  type="button"
                  icon="add"
                  onClick={this.handleOnAddClick}
                >
                  Ajouter un élevage
                </Button>

                <CustomGrid
                  className="grid-elevage k-mt-4"
                  style={{ maxHeight: "70vh" }}
                  columns={this.columns}
                  dataGrid={structures.map((item) => {
                    return {
                      ...item,
                      dateDebut:
                        item.dateDebut !== null
                          ? dayjs(item.dateDebut).format("DD/MM/YYYY")
                          : null,
                    };
                  })}
                  onRowDoubleClick={({ dataItem }) =>
                    this.goToAdminElevage(dataItem.societeAgricoleId)
                  }
                  sortable
                  sort={sort}
                  onSortChange={this.handleOnSortChange}
                />
              </>
            )}
          </div>
        </Splitter>
      </div>
    );
  }
}

export default withRouter(Elevages);
