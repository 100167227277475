import { Button, Chip } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Form, FormElement } from "@progress/kendo-react-form";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import React, { Component, Suspense, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import dataSource from "../../dataSource/dataSource";
import DonneesGenerales from "./fiche/DonneesGenerales";
import FraisElevage from "./fiche/FraisElevage";
import Assolement from "./fiche/Assolement";
import CoutFourrages from "./fiche/CoutFourrages";
import FraisFixes from "./fiche/FraisFixes";
import toastService from "../../utils/toastService";
import { RULE } from "../shared/constants";
import ReportViewerDA from "./pdf/ReportViewerDA";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const mapStateToProps = (state) => ({
  domaineId: state.auth.domaineId,
  utilisateurId: state.auth.utilisateurId,
  typeUtilisateurId: state.auth.typeUtilisateurId,
});

class SaisieDonneesAnnuelles extends Component {
  constructor(props) {
    super(props);
    this.formRef = createRef();
    this.state = {
      edit: false,
      selectedTab: 0,
      loading: true,
      donneeAnnuelle: null,
      showPrintDialog: false,
    };
  }

  async componentDidMount() {
    const { idFiche } = this.props;
    const donneeAnnuelle = await dataSource.loadDonneeAnnuelleById(idFiche);
    this.setState((s) => ({
      ...s,
      loading: false,
      donneeAnnuelle,
    }));
  }

  handleOnChange = (key, value) =>
    this.formRef.current.onChange(key, { value });

  handleOnClickEdit = () => {
    this.setState((prevState) => ({
      edit: !prevState.edit,
    }));
  };

  togglePDFPreview = () => {
    this.setState((s) => ({
      showPrintDialog: !s.showPrintDialog,
    }));
  };

  validateDA = async () => {
    this.formRef.current.onChange("cloture", {
      value: true,
    });
    await this.formRef.current.onSubmit();
    this.setState({ canEdit: false });
  };

  unValidateDA = async () => {
    this.formRef.current.onChange("cloture", {
      value: false,
    });
    await this.formRef.current.onSubmit();
    this.setState({ canEdit: false });
  };

  submitValues = async ({ values, isValid }) => {
    // si le formulaire n'est pas valide, on force `cloture` à `false`
    if (!isValid) {
      this.formRef.current.onChange("cloture", { value: false });
    } else {
      this.formRef.current.onChange("cloture", {
        value: this.formRef.current.values.cloture,
      });
    }
    try {
      await dataSource.updateDonneeAnnuelleById(values.donAnId, values);
      toastService.addToast({
        id: "da-success",
        type: "success",
        message: "Vos informations ont bien été mises à jour",
      });
    } catch (e) {
      toastService.addToast({
        id: "da-error",
        type: "error",
        message: e.message,
      });
    }
  };

  validator = (values, valueGetter) => {
    const errors = {};
    errors.donneesGenerales = {};
    errors.fraisElevage = {};
    errors.assolement = {};
    if (valueGetter("moTotExpl") === null) {
      errors.donneesGenerales = {
        ...errors.donneesGenerales,
        tabTitle: "Données générales",
        moTotExpl: {
          level: "error",
          message: "Saisie obligatoire Tot UMO Exploitation",
          tabIndex: 0,
        },
      };
    }
    if (valueGetter("moTroupCap") === null) {
      errors.assolement = {
        ...errors.assolement,
        tabTitle: "Données générales",
        moTroupCap: {
          level: "error",
          tabIndex: 0,
          message: "Saisie obligatoire Main d'oeuvre troupeau caprin",
        },
      };
    }

    if (valueGetter("asSauHa") === null) {
      errors.assolement = {
        ...errors.assolement,
        tabTitle: "Assolement",
        asSauHa: {
          level: "error",
          tabIndex: 2,
          message: "Saisie obligatoire Surface Agricole Utile (SAU)",
        },
      };
    }
    if (valueGetter("asSurfFourTotHa") === null) {
      errors.assolement = {
        ...errors.assolement,
        tabTitle: "Assolement",
        asSurfFourTotHa: {
          level: "error",
          tabIndex: 2,
          message: "Saisie obligatoire Surface fourragère totale",
        },
      };
    }

    if (
      valueGetter("asDerob1Lib") === "" &&
      (valueGetter("asDerob1ChevHa") !== null ||
        valueGetter("asDerob1ChetHa") !== null ||
        valueGetter("asDerob1TotHa") !== null)
    ) {
      errors.assolement = {
        ...errors.assolement,
        tabTitle: "Assolement",
        asDerob1Lib: {
          level: "error",
          tabIndex: 2,
          message: "Saisie obligatoire libellé dérobée 1 (si dérobée 1)",
        },
      };
    }
    if (
      valueGetter("asDerob2Lib") === "" &&
      (valueGetter("asDerob2ChevHa") !== null ||
        valueGetter("asDerob2ChetHa") !== null ||
        valueGetter("asDerob2TotHa") !== null)
    ) {
      errors.assolement = {
        ...errors.assolement,
        tabTitle: "Assolement",
        asDerob2Lib: {
          level: "error",
          tabIndex: 2,
          message: "Saisie obligatoire libellé dérobée 2 (si dérobée 2)",
        },
      };
    }

    const isFormValid = !Object.values(errors)
      .map((item) => Object.values(item).length)
      .some((item) => item > 0);
    return isFormValid ? {} : errors;
  };

  render() {
    const {
      history,
      domaineId,
      groupeId,
      typeUtilisateurId,
      match,
    } = this.props;
    const {
      selectedTab,
      edit,
      donneeAnnuelle,
      loading,
      showPrintDialog,
    } = this.state;

    return loading ? (
      <div className="k-d-flex flex-fill k-justify-content-center k-align-items-center">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <Form
        ref={this.formRef}
        initialValues={donneeAnnuelle}
        validator={this.validator}
        onSubmitClick={this.submitValues}
        render={(formProps) => {
          const {
            donneesGenerales,
            fraisElevage,
            assolement,
          } = formProps.errors;
          const decodedEleveurName = decodeURI(match.params.nomElevage);
          return (
            <div className="k-d-flex k-flex-column k-m-4">
              <FormElement>
                <div className="k-display-flex k-justify-content-between k-align-items-center k-mb-4">
                  <div className="k-d-inline-flex k-align-items-baseline">
                    <h2 className="text-color-primary k-my-0">
                      {decodedEleveurName}
                    </h2>
                    <h3 className="k-ml-4 k-my-0">
                      Données Annuelles - Date de fin :{" "}
                      {dayjs(donneeAnnuelle.dateFinCamp).format("MM-YYYY")}
                    </h3>
                  </div>
                  <div>
                    {" "}
                    <Chip
                      className="chip-statut"
                      text={
                        formProps.valueGetter("cloture") === false
                          ? "Non clôturé"
                          : "Clôturé"
                      }
                      value="chip"
                      look="outlined"
                      disabled
                    />
                  </div>
                </div>
                {[donneesGenerales, fraisElevage, assolement].some((item) => {
                  return item !== undefined && Object.keys(item).length > 0;
                }) && (
                  <div className="row">
                    <div className="panel-error k-my-4">
                      <PanelBar>
                        <PanelBarItem
                          title="Erreurs dans le formulaire"
                          className="k-state-expanded"
                          style={{ width: "100%" }}
                        >
                          {[donneesGenerales, fraisElevage, assolement].map(
                            ({ tabTitle, ...errors }) => {
                              return !!tabTitle &&
                                Object.values(errors).filter(
                                  (error) => !!error.message
                                ).length > 0 ? (
                                <div key={tabTitle}>
                                  <h3 style={{ marginBottom: "0.5em" }}>
                                    {tabTitle}
                                  </h3>
                                  {Object.entries(errors).map(
                                    ([key, { message, lines, tabIndex }]) => (
                                      <div key={key}>
                                        <Button
                                          type="button"
                                          key={key}
                                          className="k-mb-2 button-error"
                                          onClick={() =>
                                            this.setState({
                                              selectedTab:
                                                tabIndex != null ? tabIndex : 0,
                                            })
                                          }
                                        >
                                          {message}{" "}
                                          {lines && lines.length > 0 && (
                                            <span>
                                              (Ligne
                                              {lines.length > 1 && "s"}{" "}
                                              {lines
                                                .map((l) => l + 1)
                                                .join(",")}
                                              )
                                            </span>
                                          )}
                                        </Button>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null;
                            }
                          )}
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                )}

                <TabStrip
                  selected={selectedTab}
                  onSelect={({ selected }) =>
                    this.setState((s) => ({
                      ...s,
                      selectedTab: selected,
                    }))
                  }
                  className="tab-strip-responsive"
                >
                  <TabStripTab title="Données générales">
                    <DonneesGenerales
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                    />
                  </TabStripTab>
                  <TabStripTab title="Frais d'élevage">
                    <FraisElevage
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      onSubmit={formProps.onSubmit}
                    />
                  </TabStripTab>
                  <TabStripTab title="Assolement">
                    <Assolement
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                    />
                  </TabStripTab>
                  <TabStripTab title="Coût Fourrages">
                    <CoutFourrages
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                    />
                  </TabStripTab>
                  <TabStripTab title="Frais fixes et Primes">
                    <FraisFixes
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                    />
                  </TabStripTab>
                </TabStrip>
              </FormElement>
              <div className="k-display-flex k-justify-content-center sticky-footer">
                {!edit && (
                  <>
                    <Button
                      className="k-mr-4"
                      icon="close"
                      title="Fermer"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Fermer
                    </Button>
                    <Button
                      className="k-mr-4"
                      icon={"pencil"}
                      title={"Editer"}
                      onClick={() => {
                        this.handleOnClickEdit();
                      }}
                    >
                      {"Editer"}
                    </Button>
                  </>
                )}
                {(typeUtilisateurId === RULE.SUPERVISEUR_DOMAINE ||
                  typeUtilisateurId === RULE.TECHNICIEN_COOP) &&
                  !edit && (
                    <Button
                      className="k-mr-4"
                      icon="print"
                      title="Imprimer"
                      onClick={this.togglePDFPreview}
                    >
                      Voir les résulats
                    </Button>
                  )}

                {edit && (
                  <>
                    <Button
                      icon="save"
                      onClick={() => {
                        if (formProps.valueGetter("statuT_VALID_ID") !== 3) {
                          formProps.onChange("statuT_VALID_ID", {
                            value: 2,
                          });
                        }
                        formProps.onSubmit();
                      }}
                    >
                      Enregistrer les données
                    </Button>
                    <Button
                      icon="reload"
                      className="k-ml-4"
                      onClick={() => window.location.reload()}
                    >
                      Annuler la saisie
                    </Button>
                    <Button
                      className="k-ml-4"
                      icon={"undo"}
                      title={"Visualiser"}
                      onClick={() => window.location.reload()}
                    >
                      {"Visualiser"}
                    </Button>
                    {formProps.valid && (
                      <Button
                        icon={
                          formProps.valueGetter("cloture") === false
                            ? "check"
                            : "cancel"
                        }
                        className="k-ml-4"
                        onClick={
                          formProps.valueGetter("cloture") === false
                            ? this.validateDA
                            : this.unValidateDA
                        }
                        themeColor="primary"
                      >
                        {formProps.valueGetter("cloture") === false
                          ? "Appliquer le statut clôturé"
                          : "Dé-clôturer"}
                      </Button>
                    )}
                  </>
                )}
              </div>
              <Suspense fallback={<div>chargement lecteur pdf</div>}>
                <QueryClientProvider client={queryClient}>
                  {!!showPrintDialog && (
                  <ReportViewerDA
                        title={`Rapport Données Annuelles Résultats`}
                        params={{
                          domaine: domaineId,
                          anneeDateFinCamp: donneeAnnuelle?.dateFinCamp.split("-")[0],
                          moisDateFinCamp:  donneeAnnuelle?.dateFinCamp.split("-")[1],
                          groupeId: groupeId,
                          societeAgricoleId: donneeAnnuelle?.societeAgricoleId,
                          donAnId: donneeAnnuelle?.donAnId
                        }}
                        onClose={this.togglePDFPreview}
                    ></ReportViewerDA>
                      )}
                </QueryClientProvider>
              </Suspense>
            </div>
          );
        }}
      />
    );
  }
}

SaisieDonneesAnnuelles.propTypes = {
  idFiche: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  domaineId: PropTypes.number.isRequired,
  groupeId: PropTypes.number.isRequired,
  typeUtilisateurId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(SaisieDonneesAnnuelles);
