import dayjs from "dayjs";
import { Component, createRef } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { Label } from "@progress/kendo-react-labels";
import CustomGrid from "../shared/CustomGrid";
import dataSource from "../../dataSource/dataSource";
import { RULE } from "../shared/constants";

const FIELDS_CONFIGURATION = {
  coopLibelle: { type: "string" },
  type: { type: "string" },
  code: { type: "string" },
  identifiant: { type: "string" },
  nom: { type: "string" },
  prenom: { type: "string" },
  email: { type: "string" },
  codePostal: { type: "string" },
  commune: { type: "string" },
  dateDebut: { type: "date", format: "DD/MM/YYYY" },
  dateFin: { type: "date", format: "DD/MM/YYYY" },
};

class Techniciens extends Component {
  columns = [
    {
      title: "Actions",
      type: "actions",
      actions: [
        {
          title: "Modifier",
          icon: "pencil",
          action: (dataItem) =>
            this.goToAdminTechnicien(dataItem.utilisateurId),
        },
        {
          title: "Supprimer",
          icon: "trash",
          action: async (dataItem) => {
            this.toggleDialog();
            this.currentItem.current = dataItem;
          },
        },
      ],
      editable: false,
      width: 95,
    },
    {
      title: "Structures",
      type: "text",
      field: "structures",
      width: 180,
    },
    {
      title: "Type",
      type: "text",
      field: "type",
      width: 150,
    },
    {
      title: "Code",
      type: "text",
      field: "code",
      width: 40,
    },
    {
      title: "Identifiant",
      type: "text",
      field: "identifiant",
      width: 90,
    },
    {
      title: "Nom",
      type: "text",
      field: "nom",
      width: 130,
    },
    {
      title: "Prénom",
      type: "text",
      field: "prenom",
      width: 130,
    },
    {
      title: "Email",
      type: "text",
      field: "email",
      width: 120,
    },
    {
      title: "CP",
      type: "text",
      field: "codePostal",
      width: 90,
    },
    {
      title: "Ville",
      type: "text",
      field: "commune",
      width: 150,
    },
    {
      title: "Date Début",
      type: "text",
      field: "dateDebut",
      width: 90,
    },
    {
      title: "Date Fin",
      type: "text",
      field: "dateFin",
      width: 90,
    },
  ];

  constructor(props) {
    super(props);
    this.currentItem = createRef();
    this.state = {
      panes: [
        {
          keepMounted: true,
          size: "370px",
        },
        {},
      ],
      loading: false,
      techniciens: undefined,
      structures: undefined,
      filters: {
        active: true,
        code: "",
        identifiant: "",
        nom: "",
        prenom: "",
        email: "",
        structure: "",
        codePostal: "",
        ville: "",
        dateDebut: { start: "", end: "" },
        dateFin: { start: "", end: "" },
      },
      sort: [],
      dialogIsOpen: false,
      deletePending: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const structuresAll = await dataSource.loadStructures();
    const structures = structuresAll.sort((l, r) => {
      return r.coopLibelleCourt < l.coopLibelleCourt;
    });
    structures.unshift({
      code: "",
      coopId: 0,
      coopLibelleCourt: "",
      coopLibelleLong: "",
      typeCoopId: 0,
      typeCoopLibelle: "",
    });
    const typesUserAll = await dataSource.loadModaliste("TYUT");
    const typesUser = typesUserAll.filter((tu) => {
      return (
        tu.rang_modaliste === RULE.SUPERVISEUR_DOMAINE ||
        tu.rang_modaliste === RULE.TECHNICIEN_ADMIN ||
        tu.rang_modaliste === RULE.TECHNICIEN_COOP
      );
    });
    typesUser.unshift({
      abrev_modaliste: "",
      id: 0,
      libelle: "",
      modaliste_id: "",
      parent_id: null,
      rang_modaliste: null,
    });
    const techniciens = await dataSource.loadAdminTechniciens();
    this.setState({ typesUser, techniciens, structures, loading: false });
  }

  handleOnFilterChange = ({ value, target: { name } }) => {
    this.setState((s) => ({
      filters: {
        ...s.filters,
        [name]: value,
      },
    }));
  };

  handleOnDateFilterChange = (key) => ({ value: { start, end } }) => {
    this.setState((s) => ({
      filters: {
        ...s.filters,
        [key]: {
          start: dayjs(start).isValid()
            ? dayjs(start).format("YYYY-MM-DD")
            : "",
          end: dayjs(end).isValid() ? dayjs(end).format("YYYY-MM-DD") : "",
        },
      },
    }));
  };

  handleOnFilterClick = async () => {
    const { filters } = this.state;
    this.setState({ loading: true });
    const techniciens = await dataSource.loadAdminTechniciens({
      ...filters,
      active: Number(filters.active),
    });
    this.setState({ techniciens, loading: false });
  };

  handleOnKeyDown = ({ key }) => {
    if (key === "Enter") {
      this.handleOnFilterClick();
    }
  };

  goToAdminTechnicien = (id) => {
    const { history } = this.props;
    history.push(`/app/administration/techniciens/${id}`);
  };

  handleOnAddClick = () => {
    const { history } = this.props;
    history.push(`/app/administration/techniciens/nouveau`);
  };

  toggleDialog = () => {
    this.setState((s) => ({ dialogIsOpen: !s.dialogIsOpen }));
  };

  onItemDelete = async () => {
    this.setState({ deletePending: true });
    await dataSource.deleteTechnicien(this.currentItem.current.utilisateurId);
    window.location.reload();
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "350px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  handleOnSortChange = ({ sort: s }) => {
    if (s.length > 0)
      this.setState({
        sort: [{ ...s[0], ...FIELDS_CONFIGURATION[s[0].field] }],
      });
    else {
      this.setState({ sort: s });
    }
  };

  render() {
    const {
      panes,
      techniciens,
      structures,
      loading,
      filters: {
        code,
        identifiant,
        nom,
        prenom,
        email,
        codePostal,
        ville,
        active,
      },
      sort,
      dialogIsOpen,
      deletePending,
      typesUser,
    } = this.state;

    return (
      <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-4">
        <Splitter
          panes={panes}
          style={{ height: "100%" }}
          onChange={({ newState }) => this.setState({ panes: newState })}
        >
          <>
            <h2 className="k-ml-4 k-my-2 k-pt-0">Techniciens</h2>
            <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-3 admin-filter-elevage">
              <div className="k-d-flex k-align-items-baseline k-mb-3">
                <Checkbox
                  label="Actif"
                  value={active}
                  name="active"
                  onChange={({ value }) =>
                    this.handleOnFilterChange({
                      value,
                      target: { name: "active" },
                    })
                  }
                />
              </div>
              <p className="k-mb-0 k-mt-0">Filtrer les techniciens par :</p>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 100 }}>
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={code}
                    name="code"
                    onChange={this.handleOnFilterChange}
                    label="Code"
                  />
                </div>
                <div className="k-d-flex k-align-items-baseline k-mb-1">
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={identifiant}
                    name="identifiant"
                    onChange={this.handleOnFilterChange}
                    label="Identifiant"
                  />
                </div>
              </div>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 100 }}>
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={nom}
                    name="nom"
                    onChange={this.handleOnFilterChange}
                    label="Nom"
                  />
                </div>
                <div className="k-d-flex k-align-items-baseline k-mb-1">
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={prenom}
                    name="prenom"
                    onChange={this.handleOnFilterChange}
                    label="Prénom"
                  />
                </div>
              </div>
              <div className="k-d-flex k-align-items-baseline k-mb-1">
                <Input
                  onKeyDown={this.handleOnKeyDown}
                  value={email}
                  name="email"
                  onChange={this.handleOnFilterChange}
                  label="Email"
                />
              </div>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 200 }}>
                  <DropDownList
                    id="filter_structure"
                    data={structures}
                    textField="coopLibelleCourt"
                    onChange={({ value }) => {
                      this.setState((s) => ({
                        filters: {
                          ...s.filters,
                          structure: value.coopLibelleCourt,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="k-mb-1">
                  <DropDownList
                    id="filter_type_user"
                    data={typesUser}
                    textField="libelle"
                    onChange={({ value }) => {
                      this.setState((s) => ({
                        filters: {
                          ...s.filters,
                          type: value.rang_modaliste,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 100 }}>
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={codePostal}
                    name="codePostal"
                    onChange={this.handleOnFilterChange}
                    label="Code postal"
                  />
                </div>
                <div className="k-mb-1">
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={ville}
                    name="ville"
                    onChange={this.handleOnFilterChange}
                    label="Ville"
                  />
                </div>
              </div>
              <div className="k-d-flex k-flex-col k-align-items-baseline k-my-4">
                <Label className="k-font-weight-bold">Date de début</Label>
                <DateRangePicker
                  style={{ maxWidth: "100%" }}
                  startDateInputSettings={{
                    label: "du",
                  }}
                  endDateInputSettings={{
                    label: "au",
                  }}
                  onChange={this.handleOnDateFilterChange("dateDebut")}
                />
              </div>
              <div className="k-d-flex k-flex-col k-align-items-baseline k-mb-8">
                <Label className="k-font-weight-bold">Date de fin</Label>
                <DateRangePicker
                  style={{ maxWidth: "100%" }}
                  startDateInputSettings={{
                    label: "du",
                  }}
                  endDateInputSettings={{
                    label: "au",
                  }}
                  onChange={this.handleOnDateFilterChange("dateFin")}
                />
              </div>

              <Button type="button" themeColor="primary" onClick={this.handleOnFilterClick}>
                Filtrer
              </Button>
            </div>
          </>
          <div className="k-m-4">
            {loading === true && (
              <div className="flex-fill k-text-center k-mt-18">
                <Loader themeColor="primary" />
              </div>
            )}
            {loading === false && techniciens !== undefined && (
              <>
                <Button
                  title="Filtres"
                  icon="filter"
                  themeColor="primary"
                  onClick={this.toggleFilters}
                  style={{
                    top: 0,
                    position: "sticky",
                    zIndex: 1,
                  }}
                  className="k-mr-4"
                />
                <Button
                  title="Ajouter un technicien"
                  type="button"
                  icon="add"
                  onClick={this.handleOnAddClick}
                >
                  Ajouter un technicien
                </Button>

                <CustomGrid
                  className="grid-elevage k-mt-4"
                  style={{ maxHeight: "70vh" }}
                  columns={this.columns}
                  dataGrid={techniciens.map((item) => {
                    return {
                      ...item,
                      dateDebut:
                        item.dateDebut !== null
                          ? dayjs(item.dateDebut).format("DD/MM/YYYY")
                          : null,
                      dateFin:
                        item.dateFin !== null
                          ? dayjs(item.dateFin).format("DD/MM/YYYY")
                          : null,
                    };
                  })}
                  onRowDoubleClick={({ dataItem }) => {
                    this.goToAdminTechnicien(dataItem.utilisateurId);
                  }}
                  sortable
                  sort={sort}
                  onSortChange={this.handleOnSortChange}
                />
              </>
            )}
          </div>
        </Splitter>
        {dialogIsOpen && (
          <Dialog
            title={"Confirmation suppression"}
            onClose={this.toggleDialog}
          >
            <p>Confirmez-vous la suppression de la ligne sélectionnée ?</p>
            <DialogActionsBar>
              <Button onClick={this.toggleDialog}>Non</Button>
              <Button onClick={this.onItemDelete}>Oui</Button>
            </DialogActionsBar>
            {deletePending ? (
              <div className="k-d-flex k-justify-content-center">
                <Loader themeColor="primary" />
              </div>
            ) : null}
          </Dialog>
        )}
      </div>
    );
  }
}

export default withRouter(Techniciens);
