import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { compose } from "redux";
import dataSource from "../../dataSource/dataSource";
import General from "./fiche/groupe/General";
import Elevages from "./fiche/groupe/Elevages";
import Techniciens from "./fiche/groupe/Techniciens";
import toastService from "../../utils/toastService";
import truncTimeZone from "../../utils/dateTools";
import { LIMIT } from "../shared/constants";

const mapStateToProps = (state) => ({
  adminGroupeDuplicate: state.app.adminGroupeDuplicate,
});

class SaisieGroupe extends Component {
  constructor(props) {
    super(props);
    this.formRef = createRef();
    this.state = {
      selectedTab: 0,
      edit: props.edit,
      loading: false,
      groupe: undefined,
      newGroupe: undefined,
    };
  }

  async componentDidMount() {
    const { idGroupe, mode, createNew, adminGroupeDuplicate } = this.props;
    this.setState({ loading: true });
    const techniciens = await dataSource.loadTechniciens();
    if (createNew) {
      const currentTechnicien = techniciens.find((s) => s.currentUser);
      const defaultTechnicien = currentTechnicien
        ? {
            codeTechnicien: currentTechnicien.code,
            coopId: currentTechnicien.coopId,
            coopLibelle: currentTechnicien.coopLibelle,
            coopUtilisateurId: 48,
            nomTechnicien: currentTechnicien.nom,
            typeDroitResultatMensuel: 1,
          }
        : {};

      const today = dayjs().format("YYYY-MM-DD");
      this.setState({
        edit: true,
        techniciens,
        newGroupe: {
          dateDebut: today,
          techniciens: [defaultTechnicien],
        },
        loading: false,
      });
      return;
    }
    const groupe =
      mode === "duplicate"
        ? adminGroupeDuplicate
        : await dataSource.loadGroupeElevage(idGroupe);
    this.setState({ groupe, techniciens, loading: false });
  }

  handleOnClickEdit = () => this.setState((s) => ({ edit: !s.edit }));

  validator = (values, valueGetter) => {
    const errors = {};
    errors.elevage = {};
    errors.techniciens = {};

    const nomElevageNull = values?.societesAgricoles?.map(
      (item) => item.nomElevage == null
    );

    const nomTechnicienNull = values?.techniciens?.map(
      (item) => item.nomTechnicien == null
    );
    /* Si un nom d'élevage est null */
    if (nomElevageNull?.some((value) => value === true)) {
      errors.elevage = {
        ...errors.elevage,
        tabTitle: "Elevage",
        code: {
          level: "error",
          message: "Saisie du nom d'élevage obligatoire",
          tabIndex: 1,
        },
      };
    }

    /* Si un nom technicien est null */
    if (nomTechnicienNull?.some((value) => value === true)) {
      errors.techniciens = {
        ...errors.techniciens,
        tabTitle: "Techniciens",
        code: {
          level: "error",
          message: "Saisie du nom de technicien obligatoire",
          tabIndex: 2,
        },
      };
    }
    if (!valueGetter("code")) {
      errors.general = {
        ...errors.general,
        tabTitle: "Général",
        code: {
          level: "error",
          message: "Saisie obligatoire du code",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("nom")) {
      errors.general = {
        ...errors.general,
        tabTitle: "Général",
        nom: {
          level: "error",
          message: "Saisie obligatoire du libellé",
          tabIndex: 0,
        },
      };
    }
    if (
      !valueGetter("dateDebut") ||
      valueGetter("dateDebut") === "Invalid Date" ||
      (valueGetter("dateDebut") instanceof Date &&
        valueGetter("dateDebut").getFullYear() <
          LIMIT.MIN_KENDO_DATEPICKER_YEAR)
    ) {
      errors.general = {
        ...errors.general,
        tabTitle: "Général",
        dateDebut: {
          level: "error",
          message: "Saisie obligatoire de la date de début",
          tabIndex: 0,
        },
      };
    }
    if (
      valueGetter("dateFin") instanceof Date &&
      valueGetter("dateFin").getFullYear() < LIMIT.MIN_KENDO_DATEPICKER_YEAR
    ) {
      errors.general = {
        ...errors.general,
        tabTitle: "Général",
        dateFin: {
          level: "error",
          message: "La date de fin est erronée",
          tabIndex: 0,
        },
      };
    }
    const isFormValid = !Object.values(errors)
      .map((item) => Object.values(item).length)
      .some((item) => item > 0);
    return isFormValid ? {} : errors;
  };

  submitValues = async ({ values, isValid }) => {
    const societesAgricoles = values?.societesAgricoles?.map((societe) => ({
      ...societe,
      groupeParDefaut:
        societe.groupeParDefaut === true || societe.groupeParDefaut === 1
          ? 1
          : 0,
    }));
    const { createNew, idGroupe, mode } = this.props;
    if (!isValid) return;
    if (createNew || mode === "duplicate") {
      try {
        this.setState({ loading: true });
        const newGroupe = await dataSource.postGroupeElevage({
          ...values,
          dateDebut: truncTimeZone(values.dateDebut),
          dateFin: truncTimeZone(values.dateFin),
          societesAgricoles,
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été enregistrées",
        });
        window.location = `${process.env.PUBLIC_URL}/app/administration/groupes/${newGroupe.groupeId}`;
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    } else {
      try {
        await dataSource.updateGroupeElevage(idGroupe, {
          ...values,
          dateDebut: truncTimeZone(values.dateDebut),
          dateFin: truncTimeZone(values.dateFin),
          societesAgricoles,
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été enregistrées",
        });
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    }
  };

  render() {
    const { createNew, idGroupe, history } = this.props;
    const {
      selectedTab,
      edit,
      loading,
      groupe,
      techniciens,
      newGroupe,
    } = this.state;

    return loading ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <Form
        ref={this.formRef}
        initialValues={createNew ? newGroupe : groupe}
        onSubmitClick={this.submitValues}
        validator={this.validator}
        render={(formProps) => {
          return (
            <div className="k-d-flex k-flex-column k-m-4">
              <FormElement>
                {Object.keys(formProps.errors).filter(
                  (k) => typeof formProps.errors[k] === "object"
                ).length > 0 && (
                  <div className="row">
                    <div className="panel-error k-my-4">
                      <PanelBar>
                        <PanelBarItem
                          title="Erreurs dans le formulaire"
                          className="k-state-expanded"
                          style={{ width: "100%" }}
                        >
                          {Object.keys(formProps.errors)
                            .filter(
                              (k) => typeof formProps.errors[k] === "object"
                            )
                            .map((k) => formProps.errors[k])
                            .map(({ tabTitle, ...errors }) => {
                              return !!tabTitle &&
                                Object.values(errors).filter(
                                  (error) => !!error.message
                                ).length > 0 ? (
                                <div key={tabTitle}>
                                  <h3 style={{ marginBottom: "0.5em" }}>
                                    {tabTitle}
                                  </h3>
                                  {Object.entries(errors).map(
                                    ([key, { message, lines, tabIndex }]) => (
                                      <div key={key}>
                                        <Button
                                          type="button"
                                          key={key}
                                          className="k-mb-2 button-error"
                                          onClick={() =>
                                            this.setState({
                                              selectedTab:
                                                tabIndex != null ? tabIndex : 0,
                                            })
                                          }
                                        >
                                          {message}{" "}
                                          {lines && lines.length > 0 && (
                                            <span>
                                              (Ligne
                                              {lines.length > 1 && "s"}{" "}
                                              {lines
                                                .map((l) => l + 1)
                                                .join(",")}
                                              )
                                            </span>
                                          )}
                                        </Button>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null;
                            })}
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                )}
                <TabStrip
                  selected={selectedTab}
                  onSelect={({ selected }) =>
                    this.setState({
                      selectedTab: selected,
                    })
                  }
                  className="tab-strip-responsive"
                >
                  <TabStripTab title="Général">
                    <General
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      errors={formProps.errors}
                    />
                  </TabStripTab>
                  <TabStripTab title="Élevages">
                    <Elevages
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      onAddClick={() => this.setState({ edit: true })}
                      idGroupe={String(idGroupe)}
                    />
                  </TabStripTab>
                  <TabStripTab title="Techniciens">
                    <Techniciens
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      onAddClick={() => this.setState({ edit: true })}
                      techniciens={techniciens}
                    />
                  </TabStripTab>
                </TabStrip>
              </FormElement>
              <div className="k-display-flex k-justify-content-center sticky-footer">
                <Button
                  className="k-mr-4"
                  icon="close"
                  title="Fermer"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Fermer
                </Button>
                {!edit && (
                  <Button
                    className="k-mr-4"
                    icon={edit ? "undo" : "pencil"}
                    title={edit ? "Visualiser" : "Editer"}
                    onClick={() => {
                      this.handleOnClickEdit();
                    }}
                  >
                    Editer
                  </Button>
                )}
                {!edit && (
                  <Button
                    className="k-mr-4"
                    icon="print"
                    title="Imprimer"
                    onClick={this.togglePDFPreview}
                  >
                    Voir les résulats
                  </Button>
                )}

                {edit && (
                  <>
                    <Button
                      icon="save"
                      className="k-ml-4"
                      onClick={() => {
                        formProps.onSubmit();
                      }}
                    >
                      Enregistrer les données
                    </Button>
                    <Button
                      className="k-ml-4"
                      icon="reload"
                      onClick={() => window.location.reload()}
                    >
                      Annuler la saisie
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

SaisieGroupe.propTypes = {
  idGroupe: PropTypes.string,
  createNew: PropTypes.bool,
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  mode: PropTypes.string,
  adminGroupeDuplicate: PropTypes.object,
};

SaisieGroupe.defaultProps = {
  createNew: false,
  edit: false,
  mode: "insertOrUpdate",
  adminGroupeDuplicate: null,
};

export default compose(connect(mapStateToProps), withRouter)(SaisieGroupe);
