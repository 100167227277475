import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import { setDomaine } from "./redux/actions/app";
import { DOMAINE_CHEVRYPLAN, DOMAINE_OPTICHEVRE } from "./redux/reducers/app";
import store from "./redux/store";

const { PUBLIC_URL } = process.env;
const OPTICHEVRE_URL = process.env.REACT_APP_OPTICHEVRE_URL;
const myLocation = () => {
  return window.location.href;
};
store.dispatch(
  setDomaine(
    myLocation().toString().includes(OPTICHEVRE_URL)
      ? DOMAINE_OPTICHEVRE
      : DOMAINE_CHEVRYPLAN
  )
);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
