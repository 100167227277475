import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import dataSource from "../../../dataSource/dataSource";
import { SET_DM_FILTERS } from "../../../redux/actions/app";
import { RULE } from "../../shared/constants";

const mapDispatchToProps = (dispatch) => ({
  saveFilter: (filters) => {
    dispatch({ type: SET_DM_FILTERS, payload: filters });
  },
});
const mapStateToProps = (state) => {
  const now = new Date();
  return {
    domaineId: state.auth.domaineId,
    utilisateurId: state.auth.utilisateurId,
    typeUtilisateurId: state.auth.typeUtilisateurId,
    campagne: state.app.filters?.DM?.campagne
      ? state.app.filters?.DM?.campagne
      : now.getFullYear(),
    debut:
      state.app.filters?.DM?.debut !== undefined
        ? state.app.filters?.DM?.debut
        : true,
    fin:
      state.app.filters?.DM?.fin !== undefined
        ? state.app.filters?.DM?.fin
        : true,
    groupe: state.app.filters?.DM?.groupe
      ? state.app.filters?.DM?.groupe
      : null,
    elevage: state.app.filters?.DM?.elevage
      ? state.app.filters?.DM?.elevage
      : null,
    anneeDep: state.app.filters?.DM?.anneeDep
      ? state.app.filters?.DM?.anneeDep
      : now.getFullYear(),
    moisDep: state.app.filters?.DM?.moisDep
      ? state.app.filters?.DM?.moisDep
      : now.getMonth() + 1,
    statut: state.app.filters?.DM?.statut
      ? state.app.filters?.DM?.statut
      : undefined,
    societeAgricoleId: state.app.filters?.DM?.societeAgricoleId
      ? state.app.filters?.DM?.societeAgricoleId
      : null,
  };
};
class DonneesMensuellesFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupesFiltered: [],
      elevagesFiltered: [],
    };
  }

  async componentDidMount() {
    const {
      campagne,
      debut,
      fin,
      societeAgricoleId,
      groupe,
      elevage,
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      saveFilter,
      loadDonneesMensuelles,
    } = this.props;
    if (societeAgricoleId === null) {
      const groupes = await dataSource.loadGroupes({
        campagne,
        debut,
        fin,
        domaineId,
        utilisateurId,
        typeUtilisateurId,
      });
      const elevages = await dataSource.loadElevages({
        groupeId: groupe != null ? groupe.groupeId : groupes[0]?.groupeId,
        typeUtilisateurId,
        utilisateurId,
        domaineId,
      });

      const defaultGroupe = groupes.length > 0 ? groupes[0] : null;
      const defaultElevage = elevages.length > 0 ? elevages[0] : null;

      saveFilter({
        groupe: groupe != null ? groupe : defaultGroupe,
        elevage: elevage != null ? elevage : defaultElevage,
      });
      this.setState({ groupesFiltered: groupes, elevagesFiltered: elevages });

      if (elevage !== null) {
        await loadDonneesMensuelles();
      }
    }
  }

  handleOnFilterCampagneChange = async (key, value) => {
    const {
      campagne,
      debut,
      fin,
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      saveFilter,
    } = this.props;
    const groupes = await dataSource.loadGroupes({
      campagne: key === "campagne" ? value : campagne,
      debut: key === "debut" ? value : debut,
      fin: key === "fin" ? value : fin,
      domaineId,
      utilisateurId,
      typeUtilisateurId,
    });

    this.setState({ groupesFiltered: groupes });

    if (groupes === null || groupes.length === 0) {
      saveFilter({ groupe: null, elevage: null });
    } else {
      saveFilter({ groupe: groupes[0] });
      await this.handleOnFilterGroupeChange(groupes[0]);
    }
  };

  handleOnFilterGroupeChange = async (value) => {
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      groupe,
      saveFilter,
      elevage,
    } = this.props;
    const elevages = await dataSource.loadElevages({
      groupeId: value.groupeId,
      typeUtilisateurId,
      utilisateurId,
      domaineId,
    });

    if (elevages === null || elevages.length === 0) {
      saveFilter({ elevage: null });
    } else if (
      value !== null &&
      groupe !== null &&
      (value.groupeId !== groupe.groupeId || elevage === null)
    ) {
      saveFilter({ elevage: elevages[0] });
    }

    this.setState({ elevagesFiltered: elevages });
  };

  render() {
    const {
      statuts,
      loadDonneesMensuelles,
      typeUtilisateurId,
      loading,
      saveFilter,
      campagne,
      debut,
      fin,
      anneeDep,
      moisDep,
      groupe,
      elevage,
      statut,
    } = this.props;
    const { elevagesFiltered, groupesFiltered } = this.state;
    return (
      <div className="k-m-3 k-d-flex-col k-justify-content-between k-align-items-stretch">
        <div className="k-d-flex k-align-items-baseline k-mb-3">
          <NumericTextBox
            style={{ maxWidth: "7rem" }}
            value={campagne}
            onChange={({ value }) => {
              saveFilter({ campagne: value });
              this.handleOnFilterCampagneChange("campagne", value);
            }}
            label={`Campagne`}
            disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
          />
          <Checkbox
            className="k-flex-shrink-0 k-ml-2"
            value={debut}
            onChange={({ value }) => {
              saveFilter({ debut: value });
              this.handleOnFilterCampagneChange("debut", value);
            }}
            label={`Début`}
            disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
          />
          <Checkbox
            className="k-flex-shrink-0 k-ml-3 "
            value={fin}
            onChange={({ value }) => {
              saveFilter({ fin: value });
              this.handleOnFilterCampagneChange("fin", value);
            }}
            label={`Fin`}
            disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
          />
        </div>
        <ComboBox
          style={{ width: "100%" }}
          className="k-mb-3"
          name="groupe"
          label="Groupe"
          textField="nom"
          data={groupesFiltered}
          onChange={({ value }) => {
            saveFilter({ groupe: value });
            this.handleOnFilterGroupeChange(value);
          }}
          value={groupe}
          filterable
          disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
        />
        <ComboBox
          style={{ width: "100%" }}
          className="k-mb-3"
          label="Elevage"
          textField="nom"
          data={elevagesFiltered}
          disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
          value={elevage}
          onChange={({ value }) => {
            saveFilter({ elevage: value });
          }}
          filterable
        />
        <div className="k-d-flex k-align-items-baseline k-mb-3">
          <NumericTextBox
            style={{ maxWidth: "50%" }}
            format="####"
            min={2000}
            value={anneeDep}
            onChange={({ value }) => {
              saveFilter({ anneeDep: value });
            }}
            label="Année"
          />
          <NumericTextBox
            className="k-ml-3"
            style={{ maxWidth: "calc(50% - 12px)" }}
            value={moisDep}
            onChange={({ value }) => {
              saveFilter({ moisDep: value });
            }}
            label="Mois"
            format="0#"
            min={1}
            max={12}
          />
        </div>
        <DropDownList
          className="k-mb-3"
          style={{ width: "100%" }}
          value={statut}
          label="Statut"
          data={statuts}
          onChange={({ value }) => {
            saveFilter({ statut: value });
          }}
          textField="libelle"
          defaultItem={{ libelle: "Tous" }}
        />
        <Button
          className="k-mt-2"
          type="button"
          themeColor="primary"
          onClick={loadDonneesMensuelles}
          disabled={loading || elevage === null}
        >
          Filtrer
        </Button>
      </div>
    );
  }
}
DonneesMensuellesFilters.propTypes = {
  campagne: PropTypes.number.isRequired,
  debut: PropTypes.bool.isRequired,
  fin: PropTypes.bool.isRequired,
  societeAgricoleId: PropTypes.number,
  groupe: PropTypes.object,
  elevage: PropTypes.object,
  statuts: PropTypes.array.isRequired,
  statut: PropTypes.object,
  anneeDep: PropTypes.number.isRequired,
  moisDep: PropTypes.number.isRequired,
  loadDonneesMensuelles: PropTypes.func.isRequired,
  typeUtilisateurId: PropTypes.number.isRequired,
  utilisateurId: PropTypes.number.isRequired,
  domaineId: PropTypes.number.isRequired,
  saveFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
DonneesMensuellesFilters.defaultProps = {
  loading: false,
  societeAgricoleId: null,
  statut: null,
  elevage: null,
  groupe: null,
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DonneesMensuellesFilters);
