import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Splitter } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import dataSource from "../../../dataSource/dataSource";
import { SET_DM_FILTERS } from "../../../redux/actions/app";
import CustomGrid from "../../shared/CustomGrid";
import DonneesMensuellesFilters from "./DonneesMensuellesFilters";
import { RULE } from "../../shared/constants";
import ReportViewer from "../pdf/ReportViewer";
import ReportViewerDMA from "../pdfDMA/ReportViewerDMA";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const mapDispatchToProps = (dispatch) => ({
  saveFilter: (filters) => {
    dispatch({ type: SET_DM_FILTERS, payload: filters });
  },
});

const mapStateToProps = (state) => {
  const now = new Date();

  return {
    domaineId: state.auth.domaineId,
    utilisateurId: state.auth.utilisateurId,
    typeUtilisateurId: state.auth.typeUtilisateurId,
    campagne: state.app.filters?.DM?.campagne
      ? state.app.filters?.DM?.campagne
      : now.getFullYear(),
    debut: state.app.filters?.DM?.debut ? state.app.filters?.DM?.debut : true,
    fin: state.app.filters?.DM?.fin ? state.app.filters?.DM?.fin : true,
    groupe: state.app.filters?.DM?.groupe
      ? state.app.filters?.DM?.groupe
      : null,
    elevage: state.app.filters?.DM?.elevage
      ? state.app.filters?.DM?.elevage
      : null,
    anneeDep: state.app.filters?.DM?.anneeDep
      ? state.app.filters?.DM?.anneeDep
      : now.getFullYear(),
    moisDep: state.app.filters?.DM?.moisDep
      ? state.app.filters?.DM?.moisDep
      : now.getMonth() + 1,
    statut: state.app.filters?.DM?.statut
      ? state.app.filters?.DM?.statut
      : undefined,
    societeAgricoleId: state.app.filters?.DM?.societeAgricoleId
      ? state.app.filters?.DM?.societeAgricoleId
      : null,
  };
};

class SaisieDonneeMensuelles extends Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    this.state = {
      panes: [
        {
          keepMounted: true,
          size: "300px",
        },
        { scrollable: false },
      ],
      initializing: true,
      list: [],
      loading: null,
      groupes: [],
      elevages: [],
      statuts: [],
      DMExportResultats: null,
      DMExportAlimentation: null,
      ...props.location.state,

      parentGridHeight: 0,
    };
  }

  async componentDidMount() {
    const statuts = (await dataSource.loadStatuts()).sort(
      (a, b) => a.rang_modaliste - b.rang_modaliste
    );

    this.setState({ statuts, initializing: false });
  }

  componentWillUnmount() {
    if (this.resizeObserverParentGridHeight) {
      this.resizeObserverParentGridHeight.disconnect();
    }
  }

  columns = () => {
    const { typeUtilisateurId } = this.props;

    return [
      {
        title: "Actions",
        type: "actions",
        actions: [
          {
            title: "Modifier",
            icon: "pencil",
            action: (dataItem) =>
              this.goToDonneeMensuelle(dataItem.donneesMensuellesID),
          },
          {
            icon: "paste-plain-text",
            title: "Résultats",
            action: (dataItem) => {
              this.setState({
                DMExportResultats: dataItem,
              });
            },
          },
          typeUtilisateurId === RULE.SUPERVISEUR_DOMAINE
            ? {
                icon: "ascx",
                title: "Rapport Alimentation",
                action: (dataItem) => {
                  this.setState({
                    DMExportAlimentation: dataItem,
                  });
                },
              }
            : {},
        ],
        editable: () => false,
        width: 130,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Année",
        type: "number",
        field: "annee",
        width: 90,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
        cellClassName: "k-font-weight-bold",
      },
      {
        title: "Mois",
        type: "number",
        field: "mois",
        width: 90,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
        cellClassName: "k-font-weight-bold",
      },
      {
        type: "list",
        width: 110,
        title: "Statut",
        field: "statut",
        textField: "libelle",
        findPredicate: (dataItem) => (listItem) =>
          dataItem.statuT_VALID_ID === listItem.rang_modaliste,
        list: () => {
          const { statuts } = this.state;
          return statuts;
        },
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb chèvres",
        type: "number",
        field: "effectifChevresNombre",
        format: "n0",
        width: 125,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb MB",
        type: "number",
        field: "misesBasChevresNombre",
        format: "n0",
        width: 90,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb traites",
        type: "number",
        field: "nbMoyTrt",
        format: "n0",
        width: 120,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb Lot 1",
        field: "alimentationChevresLot1Nombre",
        format: "n0",
        width: 100,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb Lot 2",
        field: "alimentationChevresLot2Nombre",
        format: "n0",
        width: 100,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb Lot 3",
        field: "alimentationChevresLot3Nombre",
        format: "n0",
        width: 100,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb Lot 4",
        type: "number",
        field: "alimentationChevresLot4Nombre",
        format: "n0",
        width: 110,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Nb Lot taries",
        type: "number",
        field: "alimentationChevresLotTariNombre",
        format: "n0",
        width: 140,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        title: "Lait livré (l)",
        type: "number",
        format: "n0",
        field: "productionQuantiteLaitLivre",
        width: 120,
        editable: () => false,
        headerClassName: "k-font-weight-bold",
      },
      {
        type: "number",
        field: "productionQuantiteLaitPaye",
        title: "Lait payé",
        format: "c",
        width: 130,
        editable: () => false,
        headerClassName: "k-font-weight-bold k-pr-6",
        cellClassName: "k-pr-6",
      },
    ];
  };

  loadDonneesMensuelles = async () => {
    this.setState({ loading: true });
    const { elevage, statut, ...otherFilters } = this.props;
    let params = {
      ...otherFilters,
      societeAgricoleId: elevage !== null ? elevage.societeAgricoleId : null,
    };
    if (statut?.id) {
      params = { ...params, statut: statut.rang_modaliste };
    }
    const list = await dataSource.loadDonneesMensuelles(params);
    this.setState({
      list,
      loading: false,
    });
    if (document.getElementById("height-div") != null) {
      await this.computeGridHeight();
    }
  };

  computeGridHeight = () => {
    this.resizeObserverParentGridHeight = new ResizeObserver((entries) => {
      entries
        .filter((entry) => !!entry.borderBoxSize)
        .forEach((entry) => {
          // Chrome & Firefox implements contentBoxSize differently, integrating both
          const borderBoxSize = Array.isArray(entry.borderBoxSize)
            ? entry.borderBoxSize[0]
            : entry.borderBoxSize;
          const containerHeight = borderBoxSize.blockSize;
          this.setState({
            parentGridHeight: containerHeight,
          });
        });
    });
    if (this.resizeObserverParentGridHeight) {
      this.resizeObserverParentGridHeight.disconnect();
    }
    this.resizeObserverParentGridHeight.observe(
      document.getElementById("height-div")
    );
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "300px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  goToDonneeMensuelle = (donneesMensuellesID) => {
    const { history, elevage } = this.props;
    const { list, loading, statuts, groupes, elevages } = this.state;
    history.replace({
      pathname: "/app/donnees-mensuelles/saisie",
      state: {
        list,
        loading,
        statuts,
        groupes,
        elevages,
      },
    });
    history.push({
      pathname: `./fiche/${elevage.nom}/${donneesMensuellesID}`,
    });
  };

  render() {
    const {
      list,
      loading,
      statuts,
      initializing,
      DMExportResultats,
      DMExportAlimentation,
      panes,
      parentGridHeight,
    } = this.state;
    const { elevage, domaineId } = this.props;
    return (
      <div
        id="saisie-dm"
        className="flex-fill k-d-flex-col k-align-items-stretch k-m-4"
        // 6.5rem + 32px = taille du header + paddings
        style={{ maxHeight: "calc(100% - (6.5rem + 32px))" }}
      >
        {initializing ? (
          <div className="flex-fill k-text-center k-mt-18">
            <Loader themeColor="primary" />
          </div>
        ) : (
          <Splitter
            panes={panes}
            style={{ height: "100%" }}
            onChange={({ newState }) => this.setState({ panes: newState })}
          >
            <>
              <div className="k-d-flex-row k-align-items-baseline k-justify-content-between">
                <h2
                  className="k-m-3 k-pt-0"
                  style={{ wordBreak: "break-word" }}
                >
                  Saisie Données Mensuelles
                </h2>
              </div>

              <DonneesMensuellesFilters
                statuts={statuts}
                loadDonneesMensuelles={this.loadDonneesMensuelles}
              />
            </>
            <div
              id="height-div"
              style={{ overflowX: "auto", overflowY: "hidden", height: "100%" }}
            >
              <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <Button
                  title="Filtres"
                  icon="filter"
                  themeColor="primary"
                  onClick={this.toggleFilters}
                  style={{
                    top: 0,
                    position: "sticky",
                    zIndex: 1,
                    margin: "8px 0 0 8px",
                  }}
                />
                {loading === true && (
                  <div className="flex-fill k-text-center k-mt-18">
                    <Loader themeColor="primary" />
                  </div>
                )}
                {loading === false && (
                  <>
                    <div className="k-m-4" style={{ overflowY: "hidden" }}>
                      <CustomGrid
                        className="grid-saisie-dm"
                        style={{
                          // taille de la div parent - taille du bouton filtrer - padding
                          maxHeight: `${parentGridHeight - 66}px`,
                        }}
                        columns={this.columns()}
                        dataGrid={list}
                        onRowDoubleClick={({ dataItem }) =>
                          this.goToDonneeMensuelle(dataItem.donneesMensuellesID)
                        }
                      />
                    </div>
                  </>
                )}
                <QueryClientProvider client={queryClient}>
                  {!!DMExportResultats && (
                    <ReportViewer
                      title={`Rapport Données Mensuelles Résultats ${elevage?.nom}`}
                      params={{
                        domaine: domaineId,
                        codeEleveur: DMExportResultats.societeAgricoleId,
                        annee: DMExportResultats.annee,
                        mois: DMExportResultats.mois,
                      }}
                      onClose={() => this.setState({ DMExportResultats: null })}
                    ></ReportViewer>
                  )}
                </QueryClientProvider>
                <Suspense fallback={<div>chargement lecteur pdf</div>}>
                  <QueryClientProvider client={queryClient}>
                    {!!DMExportAlimentation && (
                      <ReportViewerDMA
                        title={`Rapport Données Mensuelles Alimentation ${elevage?.nom}`}
                        params={{
                          domaine: domaineId,
                          codeEleveur: DMExportAlimentation?.groupeId,
                          annee: DMExportAlimentation?.annee,
                          mois: DMExportAlimentation?.mois,
                          nomEleveur: elevage?.nom
                        }}
                        onClose={() => this.setState({ DMExportAlimentation: null })}
                      ></ReportViewerDMA>
                    )}
                  </QueryClientProvider>
                </Suspense>
              </Tooltip>
            </div>
          </Splitter>
        )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SaisieDonneeMensuelles);
