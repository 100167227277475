import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { PanelBar, PanelBarItem, Splitter } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { filterBy } from "@progress/kendo-data-query";
import { CSVLink } from "react-csv";
import dataSource from "../../../dataSource/dataSource";
import CustomGrid from "../../shared/CustomGrid";
import toastService from "../../../utils/toastService";
import {
  penaliteValidator,
  prixPourMLitresValidator,
  tbValidator,
  tpValidator,
} from "../../form/validator";
import { SET_DM_PDL_FILTERS } from "../../../redux/actions/app";
import { RULE } from "../../shared/constants";

const mapDispatchToProps = (dispatch) => ({
  saveFilter: (filters) => {
    dispatch({ type: SET_DM_PDL_FILTERS, payload: filters });
  },
});

const mapStateToProps = (state) => ({
  domaineId: state.auth.domaineId,
  utilisateurId: state.auth.utilisateurId,
  typeUtilisateurId: state.auth.typeUtilisateurId,
  campagne: state.app.filters?.DM_PDL?.campagne,
  debut:
    state.app.filters?.DM_PDL?.debut !== undefined
      ? state.app.filters?.DM_PDL?.debut
      : true,
  fin:
    state.app.filters?.DM_PDL?.fin !== undefined
      ? state.app.filters?.DM_PDL?.fin
      : true,
  groupeSelected: state.app.filters?.DM_PDL?.groupeSelected,
  isPaieNonSaisie: state.app.filters?.DM_PDL?.isPaieNonSaisie,
  anneeDep: state.app.filters?.DM_PDL?.anneeDep,
  moisDep: state.app.filters?.DM_PDL?.moisDep,
});

class PaieDeLait extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.formRef = React.createRef();
    this.state = {
      panes: [
        {
          keepMounted: true,
          size: "300px",
        },
        {},
      ],
      initializing: true,
      loading: false,
      dataPaieDeLait: [],
      filters: {
        campagne: now.getFullYear(),
        debut: true,
        fin: true,
        groupeSelected: null,
        anneeDep: now.getFullYear(),
        moisDep: now.getMonth() + 1,
        isPaieNonSaisie: false,
        groupes: [],
      },
      ...props.location.state,
      canEdit: false,
      isSubmit: false,
    };
  }

  async componentDidMount() {
    const now = new Date();
    const { filters } = this.state;
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      saveFilter,
      campagne,
      debut,
      fin,
      groupeSelected,
      isPaieNonSaisie,
      anneeDep,
      moisDep,
    } = this.props;

    const groupes = await dataSource.loadGroupes({
      campagne: campagne != null ? campagne : filters.campagne,
      debut: debut || filters.debut,
      fin: fin || filters.fin,
      domaineId,
      utilisateurId,
      typeUtilisateurId,
    });

    this.setState({ loading: true });

    const dataPaieDeLait = await dataSource.loadPaieDeLait(
      moisDep != null ? moisDep : filters.moisDep,
      anneeDep != null ? anneeDep : filters.anneeDep,
      groupeSelected?.groupeId != null
        ? groupeSelected?.groupeId
        : groupes[0]?.groupeId,
      isPaieNonSaisie || filters.isPaieNonSaisie
    );

    saveFilter({
      campagne: campagne || filters.campagne,
      debut: debut || filters.debut,
      fin: fin || filters.fin,
      groupeSelected: groupeSelected || filters.groupeSelected,
      isPaieNonSaisie: isPaieNonSaisie || filters.isPaieNonSaisie,
      anneeDep: anneeDep || filters.anneeDep,
      moisDep: moisDep || filters.moisDep,
    });

    this.setState({
      initializing: false,
      columns: [
        {
          title: "Ordre",
          field: "ordre",
          width: 79,
          editable: () => false,
        },
        {
          title: "Elevage",
          field: "elevage",
          width: 150,
          editable: () => false,
        },
        {
          field: "productionQuantiteLaitLivre",
          fieldProps: { spinners: false },
          title: "Lait livré",
          type: "number",
          format: "n0",
          width: 100,
          editable: () => false,
        },
        {
          field: "productionQuantiteLaitPaye",
          fieldProps: { spinners: false },
          title: "Lait payé",
          type: "number",
          width: 105,
          format: "n0",
          editable: () => true,
        },
        {
          field: "productionQuantiteLaitValeurTotaleHT",
          fieldProps: { spinners: false },
          title: "Total HT",
          format: "c",
          type: "number",
          width: 100,
          editable: () => true,
        },
        {
          field: "prixPourMLitres",
          fieldProps: { spinners: false },
          title: "Prix / 1000 L",
          type: "number",
          format: "n2",
          width: 127,
          editable: () => false,
          computedValue: (value) =>
            ["prixPourMLitres"].map((field) =>
              value.productionQuantiteLaitPaye > 0
                ? (1000 * value.productionQuantiteLaitValeurTotaleHT) /
                  value.productionQuantiteLaitPaye
                : Number(value[field])
            ),
        },
        {
          field: "primesHT",
          fieldProps: { spinners: false },
          title: "Primes HT",
          type: "number",
          width: 115,
          format: "n2",
          editable: () => true,
        },
        {
          field: "tb",
          fieldProps: { spinners: false },
          title: "TB",
          type: "number",
          width: 60,
          format: "n1",
          editable: () => true,
        },
        {
          field: "tp",
          fieldProps: { spinners: false },
          title: "TP",
          type: "number",
          width: 60,
          format: "n1",
          editable: () => true,
        },
        {
          field: "germes",
          fieldProps: { spinners: false },
          title: "Germes",
          type: "number",
          width: 98,
          format: "n0",
          editable: () => true,
        },
        {
          field: "cellules",
          fieldProps: { spinners: false },
          title: "Cellules",
          type: "number",
          width: 100,
          format: "n0",
          editable: () => true,
        },
        {
          field: "autres",
          fieldProps: { spinners: false },
          title: "Autres",
          type: "number",
          width: 87,
          format: "n0",
          editable: () => true,
        },
        {
          field: "totalPenalites",
          fieldProps: { spinners: false },
          title: "Pénalités",
          type: "number",
          width: 106,
          format: "n2",
          editable: () => false,
          computedValue: (value) =>
            [
              "germes",
              "cellules",
              "icg",
              "antibiothiqueInhibiteurs",
              "coliformes",
              "listeria",
              "lipolyse",
              "txInverse",
              "autres",
            ]
              .map((field) => Number(value[field]))
              .reduce((sum, current) => sum + current, 0),
        },
      ],
      filters: {
        ...filters,
        campagne: campagne != null ? campagne : now.getFullYear(),
        groupes,
        debut,
        fin,
        groupeSelected: groupeSelected != null ? groupeSelected : groupes[0],
        isPaieNonSaisie,
        anneeDep: anneeDep != null ? anneeDep : now.getFullYear(),
        moisDep: moisDep != null ? moisDep : now.getMonth() + 1,
      },
      dataPaieDeLait,
      loading: false,
    });
  }

  loadDataPaieDeLait = async () => {
    const { filters } = this.state;
    const dataPaieDeLait = await dataSource.loadPaieDeLait(
      filters.moisDep,
      filters.anneeDep,
      filters.groupeSelected?.groupeId || filters.groupes.length > 0
        ? filters.groupes[0].groupeId
        : null,
      filters.isPaieNonSaisie
    );
    this.setState({
      dataPaieDeLait,
      loading: false,
    });
  };

  loadGroupes = async (campagne, debut, fin) => {
    const { utilisateurId } = this.props;
    return dataSource.loadGroupes({
      campagne,
      debut,
      fin,
      utilisateurId,
    });
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "300px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  toggleEditMode = () => {
    const { canEdit } = this.state;
    this.setState((prevState) => ({
      canEdit: !prevState.canEdit,
    }));
    if (canEdit) {
      this.formRef.current.resetForm();
    }
  };

  handleOnCampagneChange = async ({ value }) => {
    const { filters } = this.state;
    const { utilisateurId, saveFilter, debut, fin } = this.props;
    const groupes =
      value.length > 0
        ? await dataSource.loadGroupes({
            campagne: value,
            debut,
            fin,
            utilisateurId,
          })
        : [];

    this.setState({
      loading: true,
    });

    saveFilter({
      campagne: String(value),
      groupeSelected: groupes.length > 0 ? groupes[0] : null,
    });

    this.setState({
      filters: {
        ...filters,
        campagne: String(value),
        groupes,
        groupeSelected: groupes.length > 0 ? groupes[0] : null,
      },
      loading: false,
    });
  };

  handleOnGroupeChange = async ({ value }) => {
    const { saveFilter } = this.props;
    const { filters } = this.state;
    this.setState({
      loading: true,
    });

    this.setState({
      loading: false,
      filters: {
        ...filters,
        groupeSelected: value,
      },
    });

    saveFilter({
      groupeSelected: value,
    });
  };

  handlePayeSaisie = async ({ value }) => {
    const { filters } = this.state;
    const { saveFilter } = this.props;
    this.setState({
      filters: {
        ...filters,
        isPaieNonSaisie: value,
      },
    });
    saveFilter({
      isPaieNonSaisie: value,
    });
  };

  filterGroupeChange = (event) => {
    this.setState({
      filters: {
        groupes: this.filterCampagneGroupeData(event.filter),
      },
    });
  };

  handleOnDoFilterClick = async () => {
    const { filters } = this.state;

    this.setState({ loading: true });
    const dataPaieDeLait = await dataSource.loadPaieDeLait(
      filters.moisDep,
      filters.anneeDep,
      filters.groupeSelected.groupeId,
      filters.isPaieNonSaisie
    );
    this.setState({
      loading: false,
      dataPaieDeLait,
    });
  };

  renderLeftPane = () => {
    const { filters } = this.state;
    const { typeUtilisateurId } = this.props;

    return (
      <>
        <h2 className="k-ml-4 k-my-2 k-pt-0">Paie de lait</h2>
        <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-3">
          <div className="k-d-flex k-align-items-baseline k-mb-3">
            <Input
              style={{ maxWidth: "7rem" }}
              onChange={this.handleOnCampagneChange}
              value={filters.campagne}
              label={`Campagne`}
              disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
            />
            <Checkbox
              className="k-flex-shrink-0 k-ml-2"
              onChange={({ value }) => {
                const { saveFilter } = this.props;
                this.setState({
                  filters: {
                    ...filters,
                    debut: value,
                  },
                });
                saveFilter({
                  debut: value,
                });
              }}
              label={`Début`}
              value={filters.debut}
              disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
            />
            <Checkbox
              className="k-flex-shrink-0 k-ml-3 "
              onChange={({ value }) => {
                const { saveFilter } = this.props;
                this.setState({
                  filters: {
                    ...filters,
                    fin: value,
                  },
                });
                saveFilter({
                  fin: value,
                });
              }}
              label={`Fin`}
              value={filters.fin}
              disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
            />
          </div>

          <div className="k-d-flex k-d-flex-col k-align-items-stretch k-align-items-baseline k-mb-3">
            <div className="k-display-flex k-align-items-baseline">
              <ComboBox
                data={filters.groupes}
                textField="nom"
                filterable
                onChange={this.handleOnGroupeChange}
                onFilterChange={this.filterGroupeChange}
                style={{ width: "100%" }}
                className="k-mb-3"
                label="Groupe"
                value={filters.groupeSelected}
                disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
                clearButton={false}
              />
            </div>
            <Checkbox
              className="k-flex-shrink-0 k-ml-2"
              onChange={this.handlePayeSaisie}
              value={filters.isPaieNonSaisie}
              label={`Afficher que les éleveurs dont la paye n'est pas saisie`}
              disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
            />
          </div>
          <div className="k-d-flex k-mb-3">
            <NumericTextBox
              format="####"
              min={2000}
              label="Année"
              style={{ maxWidth: "7rem" }}
              className="k-mr-3"
              onChange={({ value }) => {
                const { saveFilter } = this.props;
                this.setState({
                  filters: {
                    ...filters,
                    anneeDep: value,
                  },
                });
                saveFilter({
                  anneeDep: value,
                });
              }}
              value={filters.anneeDep}
            />
            <NumericTextBox
              style={{ maxWidth: "7rem" }}
              label="Mois"
              format="0#"
              min={1}
              max={12}
              onChange={({ value }) => {
                const { saveFilter } = this.props;
                this.setState({
                  filters: {
                    ...filters,
                    moisDep: value,
                  },
                });
                saveFilter({
                  moisDep: value,
                });
              }}
              value={filters.moisDep}
            />
          </div>
          <Button
            className="k-mt-4"
            type="button"
            themeColor="primary"
            onClick={this.handleOnDoFilterClick}
            disabled={filters.groupeSelected == null}
          >
            Filtrer
          </Button>
        </div>
      </>
    );
  };

  submitPaieDeLait = async (data) => {
    try {
      await dataSource.updatePaieDeLait(data.values.data);

      // show toast for success
      toastService.addToast({
        id: "dm-paiedelait-success",
        type: "success",
        message: "Vos informations ont bien été mises à jour",
      });
    } catch (err) {
      // show toast for error
      toastService.addToast({
        id: "dm-paiedelait-error",
        type: "error",
        message: err.message,
      });
    }
  };

  validator = (values) => {
    const errors = {};

    values.data.forEach((value, index) => {
      if (!prixPourMLitresValidator(value)) {
        errors.paieDeLait = {
          ...errors?.paieDeLait,
          prixPourMLitresInvalid: {
            tabIndex: 1,
            lines: [
              ...(errors.paieDeLait?.prixPourMLitresInvalid?.lines || []),
              index,
            ],
            field: "prixPourMLitres",
            message:
              "La valeur saisie pour le champ Prix / 1000 L doit être comprise entre 100 et 4000",
          },
        };
      }
      if (!tbValidator(value.tb)) {
        errors.paieDeLait = {
          ...errors.paieDeLait,
          tbInvalid: {
            tabIndex: 1,
            lines: [...(errors.paieDeLait?.tbInvalid?.lines || []), index],
            field: "tb",
            message:
              "La valeur saisie pour le champ TB doit être comprise entre 20 et 60",
          },
        };
      }
      if (!tpValidator(value.tp)) {
        errors.paieDeLait = {
          ...errors.paieDeLait,
          tpInvalid: {
            tabIndex: 1,
            lines: [...(errors.paieDeLait?.tpInvalid?.lines || []), index],
            field: "tp",
            message:
              "La valeur saisie pour le champ TP doit être comprise entre 20 et 50",
          },
        };
      }
      if (!penaliteValidator(value.totalPenalites)) {
        errors.paieDeLait = {
          ...errors.paieDeLait,
          penaliteInvalid: {
            tabIndex: 1,
            lines: [
              ...(errors.paieDeLait?.penaliteInvalid?.lines || []),
              index,
            ],
            field: "totalPenalites",
            message:
              "La valeur saisie pour le champ Pénalités doit être comprise entre 0 et 150",
          },
        };
      }
    });

    return errors;
  };

  filterCampagneGroupeData(filter) {
    const { filters } = this.state;
    return filterBy(filters.groupes, filter);
  }

  render() {
    const {
      dataPaieDeLait,
      loading,
      initializing,
      panes,
      columns,
      canEdit,
      isSubmit,
    } = this.state;

    const newDataPaieDeLait = dataPaieDeLait.map((item) => {
      return {
        ...item,
        totalPenalites: [
          "germes",
          "cellules",
          "icg",
          "antibiothiqueInhibiteurs",
          "coliformes",
          "listeria",
          "lipolyse",
          "txInverse",
          "autres",
        ]
          .map((field) => Number(item[field]))
          .reduce((sum, current) => sum + current, 0),
      };
    });

    const { history } = this.props;
    const pdlErrors = this.formRef?.current?.errors?.paieDeLait;
    const errorsToSend =
      pdlErrors != null
        ? Object.values(pdlErrors).flatMap(({ lines, field }) =>
            lines.map((l) => ({ index: l, field }))
          )
        : [];
    const headers = [
      { label: "Ordre", key: "ordre" },
      { label: "Elevage", key: "elevage" },
      {
        label: "Lait livré",
        key: "productionQuantiteLaitLivre",
      },
      {
        label: "Lait payé",
        key: "productionQuantiteLaitPaye",
      },
      {
        label: "Total HT",
        key: "productionQuantiteLaitValeurTotaleHT",
      },
      { label: "Prix / 1000 L", key: "prixPourMLitres" },
      { label: "Primes HT", key: "primesHT" },
      { label: "TB", key: "tb" },
      { label: "TP", key: "tp" },
      { label: "Germes", key: "germes" },
      { label: "Cellules", key: "cellules" },
      { label: "Autres", key: "autres" },
      { label: "Pénalites", key: "totalPenalites" },
    ];

    const csvReport = {
      data: newDataPaieDeLait,
      headers,
      filename: "paiedelait.csv",
    };
    return (
      <div
        className="flex-fill k-d-flex-col k-align-items-stretch k-m-4"
        style={{ height: "calc(100vh - 8.5rem)" }}
      >
        {pdlErrors != null && Object.keys(pdlErrors).length > 0 && (
          <div className="panel-error k-my-4">
            <PanelBar>
              <PanelBarItem
                title="Erreurs dans le formulaire"
                className="k-state-expanded"
                style={{ width: "100%" }}
              >
                <div>
                  <h3 style={{ marginBottom: "0.5em" }}>Paie de lait</h3>
                  {Object.entries(pdlErrors).map(([key, value]) => {
                    return (
                      <p key={key}>
                        {value.message}{" "}
                        {value.lines && value.lines.length > 0 && (
                          <span>
                            (Ligne
                            {value.lines.length > 1 && "s"}{" "}
                            {value.lines.map((l) => l + 1).join(",")})
                          </span>
                        )}
                      </p>
                    );
                  })}
                </div>
              </PanelBarItem>
            </PanelBar>
          </div>
        )}

        {initializing ? (
          <div className="flex-fill k-text-center k-mt-18">
            <Loader themeColor="primary" />
          </div>
        ) : (
          <Splitter
            panes={panes}
            onChange={({ newState }) => this.setState({ panes: newState })}
          >
            <>{this.renderLeftPane()}</>
            <div style={{ overflowX: "auto" }}>
              <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <Button
                  title="Filtres"
                  icon="filter"
                  themeColor="primary"
                  onClick={this.toggleFilters}
                  style={{
                    top: 0,
                    position: "sticky",
                    zIndex: 1,
                    margin: "8px 0 0 8px",
                  }}
                />
                {loading === true && (
                  <div className="flex-fill k-text-center k-mt-18">
                    <Loader themeColor="primary" />
                  </div>
                )}
                {loading === false && (
                  <div className="grid-paie-de-lait-dm">
                    <Form
                      ref={this.formRef}
                      initialValues={{ data: dataPaieDeLait }}
                      onSubmitClick={this.submitPaieDeLait}
                      validator={this.validator}
                      render={(formProps) => {
                        return (
                          <FormElement>
                            <div className="k-m-4">
                              <CustomGrid
                                style={{ maxHeight: "56vh" }}
                                isSaisieDM
                                canEdit
                                columns={columns}
                                errors={errorsToSend}
                                dataGrid={dataPaieDeLait}
                                disabled={!canEdit}
                                onChange={(newData) => {
                                  formProps.onChange("data", {
                                    value: newData,
                                  });
                                  this.setState({
                                    dataPaieDeLait: newData,
                                  });
                                }}
                              />
                            </div>
                          </FormElement>
                        );
                      }}
                    />
                    <div className="k-display-flex k-justify-content-center sticky-footer">
                      {!canEdit && (
                        <>
                          <Button
                            className="k-mr-4"
                            icon="close"
                            title="Fermer"
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            Fermer
                          </Button>
                          <Button
                            icon={"pencil"}
                            title={"Editer"}
                            onClick={() => {
                              this.toggleEditMode();
                              this.setState({ isSubmit: false });
                            }}
                            themeColor={isSubmit ? 'primary' : null}
                          >
                            {"Editer"}
                          </Button>
                          <Button
                            className="k-ml-4"
                            icon="print"
                            title="Imprimer en csv"
                          >
                            <CSVLink
                              {...csvReport}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              Imprimer en csv
                            </CSVLink>
                          </Button>
                        </>
                      )}
                      {canEdit && (
                        <>
                          <Button
                            icon="save"
                            className="k-ml-4"
                            themeColor="primary"
                            onClick={() => {
                              if (
                                this.formRef.current.valueGetter(
                                  "statuT_VALID_ID"
                                ) !== 3
                              ) {
                                this.formRef.current.onChange(
                                  "statuT_VALID_ID",
                                  {
                                    value: 2,
                                  }
                                );
                              }
                              this.formRef.current.onSubmit();
                              this.setState({
                                isSubmit: true,
                              });
                            }}
                          >
                            Enregistrer les données
                          </Button>
                          <Button
                            className="k-ml-4"
                            icon="reload"
                            onClick={() => window.location.reload()}
                          >
                            Annuler la saisie
                          </Button>
                          <Button
                            className="k-ml-4"
                            icon={"undo"}
                            title={"Visualiser"}
                            onClick={() => window.location.reload()}
                            themeColor={isSubmit ? 'primary' : null}
                          >
                            {"Visualiser"}
                          </Button>
                          <Button
                            className="k-ml-4"
                            icon="print"
                            title="Imprimer en csv"
                          >
                            <CSVLink
                              {...csvReport}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              Imprimer en csv
                            </CSVLink>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Tooltip>
            </div>
          </Splitter>
        )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(PaieDeLait);
